/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import axios, {
  AxiosError,
  RawAxiosRequestHeaders,
  AxiosHeaders,
  HeadersDefaults,
} from 'axios';
import { BaseErrorResponse } from '../data/response/BaseErrorResponse';
import Constants from './Constants';
import CookieUtils from './CookieUtils';
import Utils from './Utils';

type HeaderType =
  | RawAxiosRequestHeaders
  | AxiosHeaders
  | Partial<HeadersDefaults>;

export default (baseURL: string, headers?: HeaderType, ...config: any) => {
  let defaultHeaders: HeaderType = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  let defaultConfig = {
    timeout: 120000,
  };
  if (headers) {
    defaultHeaders = { ...defaultHeaders, ...headers };
  }
  if (config) {
    defaultConfig = { ...defaultConfig, ...config };
  }

  const axiosInstance = axios.create({
    baseURL,
    headers: defaultHeaders,
    ...defaultConfig,
  });

  axiosInstance.interceptors.request.use(async request => {
    if (!request.url?.includes('auth/')) {
      const token = await getToken();
      if (token) request.headers.Authorization = token;
      else {
        Promise.reject(new AxiosError('unauthorized', '406'));
      }
    }
    return request;
  });

  axiosInstance.interceptors.response.use(
    responseIntercept,
    async (error: BaseErrorResponse) => {
      console.log(
        `AXIOSINTERCEPTORLOG -> RESPONSE ERROR -> api=${error?.config?.url}`,
        error,
      );

      const errorMessage = Utils.parseError(error);
      if (errorMessage) {
        if (errorMessage.toLowerCase()?.includes('cognito')) {
          return Promise.reject(new AxiosError(errorMessage ?? '', '406'));
        }
      }

      if (error.response?.status === 410) {
        return Promise.reject(
          new AxiosError(
            'User is associated with business account',
            error.response?.status?.toString() ?? '',
          ),
        );
      }

      return Promise.reject(
        new AxiosError(
          errorMessage ?? '',
          error.response?.status?.toString() ?? '',
        ),
      );
    },
  );

  return axiosInstance;
};

function responseIntercept(response: any): typeof response {
  console.log(
    `AXIOSINTERCEPTORLOG -> RESPONSE SUCCESS -> api=${response.config.url}`,
    response,
  );
  return response;
}

async function getToken(): Promise<string> {
  const accessToken = CookieUtils.getUserToken();
  const refreshToken = CookieUtils.getRefreshToken();

  if (accessToken) {
    return accessToken;
  }
  if (refreshToken) {
    const response = await axios<any, any>(
      `${Constants.baseUrl}/auth/refresh-token`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'refresh-token': refreshToken,
        },
      },
    );

    const { data } = response.data;
    CookieUtils.setUserToken(data.access_token, Number(data.expires_in));

    return data.access_token;
  }
  return '';
}
