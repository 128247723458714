/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  PwnedStatus,
  ReadStatus,
  sortByStatus,
} from '../data/Enum/PwnedStatus';
import { Roles } from '../data/Enum/Roles';
import User from '../data/local/User';
import { AssetResponse, AssetInfo } from '../data/response/AssetResponse';
import { BaseErrorResponse } from '../data/response/BaseErrorResponse';
import BaseResponse from '../data/response/BaseResponse';
import PwnedResponse from '../data/response/PwnedResponse';

export default class Utils {
  static debounce<T extends Function>(call: T, wait: number) {
    let timer: ReturnType<typeof setTimeout>;
    const callable = (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => call(...args), wait);
    };
    return callable;
  }

  static parseError = (error: BaseErrorResponse): string | undefined => {
    if (
      Object.prototype.toString.call(error.response?.data?.message) ===
      '[object Object]'
    ) {
      return (error.response?.data.message as { domain: string[] }).domain[0];
    }
    return error.response?.data?.message as string | undefined;
  };

  static canView(
    user: User | undefined,
    site: 'darkweb' | 'score' | 'organization' | 'consumer',
  ): boolean {
    if (site === 'darkweb' || site === 'consumer') {
      return user?.roles.map(it => it.id).includes(Roles.INSURED) ?? false;
    }
    if (site === 'score' || site === 'organization') {
      return user?.roles.map(it => it.id).includes(Roles.BUSINESS) ?? false;
    }
    return false;
  }

  static filterBreaches(
    breaches: PwnedResponse[],
    status: PwnedStatus,
    readStatus: ReadStatus,
    sortBy: sortByStatus,
  ): PwnedResponse[] {
    const sortedBreaches = this.sortByDate(breaches, sortBy);

    if (status === 'All' && readStatus === 'All') return sortedBreaches;

    const statusFiltered = sortedBreaches.filter(
      it => status === 'All' || it.statusName === status,
    );

    if (readStatus === 'All') return statusFiltered;

    return statusFiltered.filter(it =>
      readStatus === 'Read' ? !!it.id : !it.id,
    );
  }

  static sortByDate(
    data: PwnedResponse[],
    sortBy: sortByStatus,
  ): PwnedResponse[] {
    return data.sort((a, b) => {
      const dateA = new Date(a.BreachDate).getTime();
      const dateB = new Date(b.BreachDate).getTime();

      if (sortBy === 'Date - New to old') return dateB - dateA;

      return dateA - dateB;
    });
  }

  static isSevenDaysOld(date: number): boolean {
    const today = new Date().getTime() / 1000;
    const checkDate = new Date(date).getTime();
    const diffTime = Math.abs(checkDate - today);
    const diffDays = diffTime / (60 * 60 * 24);
    return diffDays > 7;
  }

  static getStringArray(string: string): string[] {
    return string
      .replace('[', '')
      .replace(']', '')
      .replaceAll('"', '')
      .split(',');
  }

  static setPwnedStatus(
    response: BaseResponse<PwnedResponse[]>,
  ): BaseResponse<PwnedResponse[]> {
    // 0 = all
    // 1 = edit
    // 2 = removed
    const data = response.data.map(item => {
      if (item.status === 1) {
        return { ...item, statusName: 'New' as PwnedStatus };
      }
      if (item.status === 2) {
        return { ...item, statusName: 'Removed' as PwnedStatus };
      }
      return { ...item, statusName: 'All' as PwnedStatus };
    });

    return { ...response, data };
  }

  static formatPrice(price: number) {
    let string = '';
    if (price >= 1_000_000) {
      string = `$${(price / 1_000_000).toFixed(2)}M`;
    } else if (price >= 1_000) {
      string = `$${(price / 1_000).toFixed(1)}K`;
    } else string = `$${price.toString()}`;
    return string.replace('.0K', 'K').replace('.00M', 'M');
  }

  static getFormattedDate(ArgDate: string) {
    const date = new Date(ArgDate);
    const year = date.getFullYear().toString(); // Get the year
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ensure 2-digit month
    const day = date.getDate().toString().padStart(2, '0'); // Ensure 2-digit day

    return `${year}-${month}-${day}`;
  }

  static formatDateToLongString(dateString: string): string {
    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    };
    // Get the day with the appropriate suffix (e.g., 1st, 2nd, 3rd)
    const day: number = date.getDate();
    const dayWithSuffix: string =
      day +
      ((day >= 11 && day <= 13) || day % 10 !== 1
        ? day % 10 === 2
          ? 'nd'
          : day % 10 === 3
          ? 'rd'
          : 'th'
        : 'st');

    const formattedDate: string = date
      .toLocaleDateString('en-US', options)
      .replace(/\d+/, dayWithSuffix);

    return formattedDate;
  }

  static setScoreDataMessage(
    response: BaseResponse<AssetResponse>,
  ): BaseResponse<AssetResponse> {
    const assets = response.data.asset_info?.assets.map(it => {
      let message = '';

      if (it.ports.length)
        message = `close these ports [${it.ports.join(',')}]`;
      else if (it.codes.includes('ssh'))
        message = `Update SSH software to fix vulnerable protocol`;
      else if (it.codes.includes('ssh-e'))
        message = `renew you ssh certificate`;
      else if (it.codes.includes('ssh-r'))
        message = `Contact the issuing authority or certificate provider immediately`;
      else if (it.codes.includes('ssh-wa'))
        message = `Strengthen certificate algorithm for security`;
      else if (it.codes.includes('ssh-ss'))
        message = `Get trusted certificate for better security`;

      return { ...it, message };
    });

    const assetInfo = { ...response.data.asset_info, assets } as AssetInfo;

    return { ...response, data: { ...response.data, asset_info: assetInfo } };
  }
}
