// userValidations.js
import * as Yup from 'yup';

// const restrictedCharacters = [
//   '<',
//   '>',
//   '"',
//   "'",
//   ';',
//   '/',
//   '\\',
//   '%',
//   '(',
//   ')',
//   '$',
//   '&',
//   '@',
//   '*',
//   ' ',
//   '\n',
//   '\r',
//   '[',
//   ']',
//   '{',
//   '}',
//   '|',
//   '?',
//   '!',
// ];

// const restrictedCharactersDarkWeb = [
//   '<',
//   '>',
//   '"',
//   "'",
//   ';',
//   '/',
//   '\\',
//   '%',
//   '(',
//   ')',
//   '$',
//   '&',
//   '*',
//   ' ',
//   '\n',
//   '\r',
//   '[',
//   ']',
//   '{',
//   '}',
//   '|',
//   '?',
//   '!',
// ];

export const basicFieldValidation = Yup.string()
  .required('This field is required')
  .max(100, 'Maximum length must be 100 characters');
// .test('', 'Invalid characters', value => {
//   return !value || !restrictedCharacters.some(char => value.includes(char));
// });

export const passwordValidation = Yup.string()
  .required('This field is required')
  .matches(/^[^\s]+$/, 'Whitespace not allowed')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{12,128})/,
    'Must Contain at least 12 Characters, One Uppercase, One Lowercase, One Number, and One Special Case Character, with a maximum of 128 characters',
  )
  .max(128, 'Maximum length must be 128 characters');

export const emailValidation = Yup.string()
  .required('This field is required')
  .max(128, 'Maximum length must be 128 characters')
  .matches(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    'Invalid email',
  );

export const corporateNameValidation = Yup.string()
  .max(100, 'Maximum length must be 100 characters')
  // .test('', 'Invalid characters', value => {
  //   return !value || !restrictedCharacters.some(char => value.includes(char));
  // })
  .default('');

export const policyNumberValidation = Yup.string()
  .max(100, 'Maximum length must be 100 characters')
  // .test('', 'Invalid characters', value => {
  //   return !value || !restrictedCharacters.some(char => value.includes(char));
  // })
  .default('');

export const codeValidation = Yup.string()
  .required('This field is required')
  .length(6, 'Invalid code')
  .matches(/^[0-9]+$/, 'Invalid code');

export const searchBarValidation = Yup.string()
  .required('Please enter an email or phone number to search')
  // .test('hello', 'Invalid domain', value => {
  //   return !value || !restrictedCharacters.some(char => value.includes(char));
  // })
  .lowercase()
  .max(100, 'Maximum length limit exceeded');

export const emailOrPhoneNumberValidation = Yup.string()
  .required('Please enter email or phone number to search')
  .max(100, 'Maximum length must be 100 characters');
// .test('', 'Invalid characters', value => {
//   return (
//     !value || !restrictedCharactersDarkWeb.some(char => value.includes(char))
//   );
// });
