/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import React, { InputHTMLAttributes, useState } from 'react';
import { useController } from 'react-hook-form';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  type: string;
  isPasswordField?: boolean;
  label?: string;
  labelTextColor?: string;
  // formSubmit error is used in login Screen to show form submission error ex: incorrect credentials
  formSubmitError?: boolean;
  disabled?: boolean;
}

export default function Input({
  isPasswordField = false,
  formSubmitError = false,
  disabled = false,
  labelTextColor = 'text-gray-700',
  label = '',
  ...props
}: InputProps) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(isPasswordField);
  const { field, fieldState } = useController({ name: props.name });

  let formatting = 'placeholder-gray-400 text-[#56565C]';
  let borderStyle = 'border-[#9090AF]';

  if (disabled)
    formatting =
      'bg-[#FBFBFC] placeholder-gray-200 text-[#56565C] border-[#DFDFE2]';

  if (fieldState.error || formSubmitError) borderStyle = 'border-red-600';

  return (
    <div>
      {label && (
        <label
          className={`${labelTextColor} block text-sm font-normal mb-1 `}
          htmlFor='grid-text-1'
        >
          {label}
        </label>
      )}
      <div className='relative w-full container '>
        <input
          {...props}
          type={!isPasswordVisible ? 'text' : props.type}
          disabled={disabled}
          value={field.value}
          name={field.name}
          onChange={value => field.onChange(value)}
          className={`${formatting} ${borderStyle} appearance-none block w-full px-3 py-1 border-2 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:border-2 text-md lg:text-md `}
        />
        {isPasswordField && field.value?.length > 0 && (
          <div
            onClick={() => {
              setIsPasswordVisible(prev => !prev);
            }}
            className='absolute bg-white h-4/5 mx-1 m-auto outline-none inset-y-0 right-0 flex items-center  text-gray-600 cursor-pointer '
          >
            <svg width='55' height='50'>
              <text x='10' y='30' fill='#444' fontWeight='600'>
                {isPasswordVisible ? 'Show' : 'Hide'}
              </text>
            </svg>
          </div>
        )}
      </div>
      {fieldState.error?.message && (
        <p className='text-xs mt-1 text-red-400'>{fieldState.error?.message}</p>
      )}
    </div>
  );
}
