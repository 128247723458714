/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ITestCard {
  image: string;
  heading: string;
  navigateTo: string;
  description: string;
  score?: number | null;
}

function DataTile(props: ITestCard) {
  const navigate = useNavigate();
  const onCardClick = () => {
    navigate(props.navigateTo);
  };
  return (
    <div
      className='flex space-x-5 border-2 p-4  px-6  rounded bg-white hover:bg-gray-50 cursor-pointer'
      onClick={onCardClick}
    >
      <div className='flex justify-center items-center'>
        <img
          //   src={Assets.svg.DarkWeb_Search}
          src={props.image}
          alt='search'
          className='w-28 rounded'
        />
      </div>
      <div className='flex-1 flex flex-col space-y-3'>
        <h1 className='page-heading-5'>{props.heading}</h1>
        <hr className='border-gray-400' />
        <p className='text-xs tall:text-sm'>{props.description}</p>
        {props.score && (
          <div
            className={`${
              props.score < 8 && 'text-[#8F8F91] '
            } leading-normal text-sm self-end font-semibold tracking-wide`}
          >
            {`Score: ${props.score}/15`}
          </div>
        )}
      </div>
    </div>
  );
}

export default DataTile;
