/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { FormProvider } from 'react-hook-form';
import Switch from './components/Switch';
import Button from '../../app/Button';
import Input from '../../app/Input';
import useAccountSettings from './useAccountSettings';

interface settingProps {
  showZAsessment: boolean;
  setShowzZAssement: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function AccountSetting(_props: settingProps) {
  const { form, onFormSubmit, isLoading, onCancelChanges } =
    useAccountSettings();

  return (
    <div className='min-h-full'>
      <div className=' p-4 xl:px-8 xl:py-4  flex flex-col space-y-2 sm:space-y-8'>
        <h2 className='page-heading'>Account Details</h2>

        <form className='xl:w-3/5 bg-white p-6 border border-gray-200 rounded-lg'>
          <FormProvider {...form}>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-5'>
              <div className='col-span-2 sm:col-span-1'>
                <Input
                  type='text'
                  name='first_name'
                  placeholder='enter first name'
                  label='First name'
                  disabled={isLoading}
                />
              </div>
              <div className='col-span-2 sm:col-span-1'>
                <Input
                  type='text'
                  name='last_name'
                  placeholder='enter last name'
                  label='Last name'
                  disabled={isLoading}
                />
              </div>
              <div className='col-span-2 '>
                <Input
                  type='email'
                  name='email'
                  disabled
                  placeholder='enter email address'
                  label='My email'
                />
              </div>
              {/* <Input
              type='password'
              name='password'
              placeholder='Password'
              label='Password'
            /> */}
              {/* <div className='col-span-2 sm:col-span-1'>
                <Input
                  type='text'
                  name='corporate_name'
                  label='Corporate Name'
                  placeholder='enter corporate name'
                  disabled={isLoading}
                />
              </div> */}
              <div className='col-span-2 '>
                <Input
                  type='text'
                  name='policy_number'
                  label='Policy number'
                  placeholder='No policy found'
                />
              </div>
              <Switch name='weekly_email' label='Weekly Newsletter' />
              {/* <div className='col-span-2 mt-2'>
              <label className='relative inline-flex items-center cursor-pointer'>
                <input
                  type='checkbox'
                  value=''
                  checked={props.showZAsessment}
                  onChange={() =>
                    props.setShowzZAssement(!props.showZAsessment)
                  }
                  className='sr-only peer'
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-300 dark:peer-focus:ring-primary-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary-600" />
                <span className='ml-3 text-sm font-medium text-gray-900 dark:text-gray-300'>
                  Zurich Assessment
                </span>
              </label>
            </div> */}
              <div className='col-span-2  mt-6  '>
                <div className='flex  flex-col space-y-2 sm:space-y-0 sm:flex-row sm:space-x-2 sm:max-w-[400px] sm:mx-auto sm:mr-1'>
                  <div className='w-full'>
                    <Button
                      title='Cancel changes'
                      onClick={onCancelChanges}
                      isDisabled={!form.formState.isDirty || isLoading}
                      isGray
                    />
                  </div>
                  <div className='w-full'>
                    <Button
                      title='Update details'
                      onClick={onFormSubmit}
                      isDisabled={!form.formState.isDirty}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </FormProvider>
        </form>
      </div>
    </div>
  );
}
