import article1 from '../../../../assets/emergencyRoom/emergency_support_tile_1.webp';
import article2 from '../../../../assets/emergencyRoom/emergency_support_tile_2.webp';
import article3 from '../../../../assets/emergencyRoom/emergency_support_tile_3.webp';
import article4 from '../../../../assets/emergencyRoom/emergency_support_tile_4.webp';

export default [
  {
    image: article1,
    title: 'Don’t be fooled, phishing is no joke...',
    description:
      'We recently conducted a test to highlight the threats of spear phishing. We did this to show how easy it is to be tricked by a phishing email...',
    NavigateTo: '/emergency-room/article-1',
  },
  {
    image: article2,
    title: 'Hackers and online scams',
    description:
      'Few Canadians were spared from the national outage experienced on Friday July 8th. Adding insult to injury, savvy hackers are using this outrage to their advantage.',
    NavigateTo: '/emergency-room/article-2',
  },
  {
    image: article3,
    title: 'Spear-phishing: What is it? What to do?',
    description:
      'In a cyber incident reported to us last week, a senior employee in the insured’s finance department discovered their email address had been compromised...',
    NavigateTo: '/emergency-room/article-3',
  },
  {
    image: article4,
    title: 'Reducing the risk of RDP brute force attacks',
    description:
      'RDP brute force attacks are commonly used by hackers targeting remote working employees, and this new control will make brute forcing much harder.',
    NavigateTo: '/emergency-room/article-4',
  },
];
