import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useEffect } from 'react';
import {
  useResend,
  useResetPassword as useResetMutation,
} from '../../../app/hooks/useUser';
import { emailValidation } from '../../../utils/validations';

const schema = yup.object({
  email: emailValidation,
  app_id: yup.string().default('2XybYTuPwHu7dtWPuie4llHMgb1'),
});
type FormType = yup.InferType<typeof schema>;

export default function useResetPassword() {
  const reset = useResetMutation();
  const resendToken = useResend();
  const navigate = useNavigate();

  const form = useForm<FormType>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const func = async () => {
      if (reset.error?.code === '409') {
        // toast.error('Please verify your email');
        await resendToken.mutateAsync({
          email: form.getValues('email'),
          app_id: '2XybYTuPwHu7dtWPuie4llHMgb1',
        });
        setTimeout(() => {}, 4000);

        navigate({
          pathname: '/verify',
          search: `?email=${encodeURIComponent(form.getValues('email'))}`,
        });
      }
    };
    func();

    return () => {};
  }, [reset.isError]);

  const onFormSubmit = async (data: FormType) => {
    await reset.mutateAsync({ ...data });
    toast.success('Email has been sent with verification code');
    navigate(
      {
        pathname: '/new-password',
        search: `?email=${encodeURIComponent(form.getValues('email'))}`,
      },
      // { replace: true, state: { email: form.getValues('email') } },
    );
  };

  return {
    form,
    reset,
    onFormSubmit: form.handleSubmit(onFormSubmit),
  };
}
