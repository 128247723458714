import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../app/contexts/AuthContext';
import CookieUtils from '../../../utils/CookieUtils';
import { useLogin, useResend } from '../../../app/hooks/useUser';
import { emailValidation } from '../../../utils/validations';

const schema = yup.object({
  email: emailValidation,
  password: yup.string().required('Password is required'),
  app_id: yup.string().default('2XybYTuPwHu7dtWPuie4llHMgb1'),
});

type FormType = yup.InferType<typeof schema>;

export default function useSignIn() {
  const login = useLogin();
  const resendToken = useResend();
  const auth = useAuth();
  const navigate = useNavigate();

  const form = useForm<FormType>({
    defaultValues: {
      email: '',
      password: '',
      app_id: '2XybYTuPwHu7dtWPuie4llHMgb1',
    },
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    const func = async () => {
      if (
        login.error?.code === '401' ||
        login.error?.message.includes('User is not confirmed')
      ) {
        await resendToken.mutateAsync({
          email: form.getValues('email'),
          app_id: '2XybYTuPwHu7dtWPuie4llHMgb1',
        });
        setTimeout(() => {}, 3000);
        navigate({
          pathname: '/verify',
          search: `?email=${encodeURIComponent(form.getValues('email'))}`,
        });
      }
    };
    func();
  }, [login.isError]);

  const onFormSubmit = async (formData: FormType) => {
    const { data } = await login.mutateAsync(formData);
    CookieUtils.setUserToken(data.access_token, Number(data.expires_in));
    CookieUtils.setRefreshToken(data.refresh_token);
    auth?.handleLogin(data.access_token);
  };

  return {
    form,
    onFormSubmit: form.handleSubmit(onFormSubmit),
    error: login.error,
    isLoading: login.isLoading || resendToken.isLoading,
  };
}
