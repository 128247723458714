import Question from '../Question';
import Option from '../Option';

const HouseholdQuestion: Question[] = [
  new Question(
    '1',
    'If you find it difficult to remember strong passwords, it would help if you ...',
    [
      new Option('1', 'Downloaded a password manager'),
      new Option('2', 'Wrote the passwords in a notebook'),
      new Option('3', 'Use auto-logins on a web site'),
      new Option('4', 'Send yourself an email containing your password'),
    ],
    '2',
  ),

  new Question(
    '2',
    'What is the minimum length for a strong password?',
    [
      new Option('1', '6 characters'),
      new Option('2', '8 characters'),
      new Option('3', '10 characters'),
      new Option('4', '12 characters'),
    ],
    '2',
  ),

  new Question(
    '3',
    'Why should you be concerned about ransomware?',
    [
      new Option('1', 'It might expose my personal data on the internet'),
      new Option('2', "It might encrypt my data so that I can't access it"),
      new Option('3', 'It might access my bank account and take mone'),
      new Option('4', 'It might use all my network bandwidth'),
    ],
    '1',
  ),

  new Question(
    '4',
    'You have received an email from your bank asking you to log in to your account within a week. The email includes a logo that looks genuine and a link to access your account. What should you do?',
    [
      new Option(
        '1',
        'Follow the link and log in to my account, as the email looks genuine',
      ),
      new Option(
        '2',
        "Go to the bank's website by typing the URL manually, and log in to my account ",
      ),
      new Option(
        '3',
        'Install antispam software to automatically block messages from people not in my contacts',
      ),
      new Option(
        '4',
        'Follow the link and enter false information to annoy the hackers, as this is likely to be phishing',
      ),
    ],
    '2',
  ),

  new Question(
    '5',
    'Malware is...',
    [
      new Option(
        '1',
        'badly written software that can be annoying, but is not a security risk',
      ),
      new Option(
        '2',
        'malicious software that can have a big impact on my device or information',
      ),
      new Option(
        '3',
        'required software that is part of the operating system on my device',
      ),
      new Option(
        '4',
        'software that helps to protect my device from viruses and other threats',
      ),
    ],
    '2',
  ),

  new Question(
    '6',
    'How can you be sure that all data you send to a website is encrypted?',
    [
      new Option(
        '1',
        'I should check that the status bar on my web browser shows a padlock icon',
      ),
      new Option('2', 'I should use an encrypted web browser'),
      new Option(
        '3',
        'I should look for the word "encrypted" in the first heading on the web page',
      ),
      new Option(
        '4',
        'I should encrypt the data on my device before I connect to the website',
      ),
    ],
    '2',
  ),

  new Question(
    '7',
    'What is the MOST important advice to follow to use social media safely?',
    [
      new Option('1', 'Do not use social media for work-related activities'),
      new Option(
        '2',
        'Keep the number of posts you create each day to a minimum',
      ),
      new Option('3', 'Do not disclose sensitive personal or work information'),
      new Option('4', 'Make sure you write posts in a clear and polite way'),
    ],
    '3',
  ),

  new Question(
    '8',
    'Which of these is suitable password?',
    [
      new Option('1', 'P4ssw0r3'),
      new Option('2', 'ui2$bW3U'),
      new Option('3', 'myaccountsecret'),
      new Option('4', 'gd!x$'),
    ],
    '4',
  ),
  new Question(
    '9',
    'Which of these is NOT a social media network?',
    [
      new Option('1', 'Email'),
      new Option('2', 'LinkedIn'),
      new Option('3', 'Twitter'),
      new Option('4', 'Instagram'),
    ],
    '1',
  ),
  new Question(
    '10',
    'Someone has recently added you as a friend on a social networking site. They have asked you to post personal information on the site. What should you do?',
    [
      new Option(
        '1',
        "Write a public post asking for confirmation of the new friend's identity",
      ),
      new Option(
        '2',
        'Refuse to share the information and ask the new friend to verify their identity',
      ),
      new Option(
        '3',
        "Ask for the new friend's email address and send the information privately",
      ),
      new Option('4', 'Post the personal information on the site as requested'),
    ],
    '2',
  ),
  new Question(
    '11',
    'Why should you be careful about posting work-related information on a social networking site?',
    [
      new Option(
        '1',
        'Because social networking sites are not used for work purposes',
      ),
      new Option(
        '2',
        'Because other social network users are not interested in work-related information',
      ),
      new Option(
        '3',
        "Because it won't be kept up to date and will become inaccurate",
      ),
      new Option(
        '4',
        "Because it may be sensitive information which shouldn't be shared",
      ),
    ],
    '4',
  ),
  new Question(
    '12',
    'What should you do when using social media?',
    [
      new Option(
        '1',
        'Use the privacy settings to make sure everyone can see my updates',
      ),
      new Option(
        '2',
        "Beware of suspicious links and attachments and don't click on them",
      ),
      new Option(
        '3',
        "Only report social media abuse when I'm absolutely sure it is intentional",
      ),
      new Option(
        '4',
        'Use a single password for all sites to make it easier to remember',
      ),
    ],
    '2',
  ),
  new Question(
    '13',
    'Why are removable storage devices a threat to computer security?',
    [
      new Option('1', 'Removable storage devices are not a threat to security'),
      new Option('2', 'Because they are housed inside a particular computer'),
      new Option(
        '3',
        'Because they allow easy sharing of both data and viruses',
      ),
      new Option(
        '4',
        'Because they are centralized and accessible to everyone',
      ),
    ],
    '3',
  ),
  new Question(
    '14',
    'What should users do to protect their devices before they get lost or stolen?',
    [
      new Option('1', 'Use passcodes/PINs to lock the screen'),
      new Option('2', 'Enable phone-finding and remote-wiping capabilities'),
      new Option(
        '3',
        "Set the screen to display the owner's contact information",
      ),
      new Option('4', 'All of the above'),
    ],
    '4',
  ),
  new Question(
    '15',
    'What is a secure data container?',
    [
      new Option('1', 'A lockbox where users can store their mobile devices'),
      new Option(
        '2',
        'An authenticated and encrypted storage area on a device that holds sensitive files',
      ),
      new Option(
        '3',
        'A program or platform that requires a password to log-in',
      ),
      new Option('4', 'A password vault'),
    ],
    '2',
  ),
];

export default HouseholdQuestion;
