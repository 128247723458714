/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useNavigate } from 'react-router';
import Buttons from './Buttons';
import ProgressBar from '../../app/assessment/ProgressBar';
import QuestionUI from './Question';
import Assets from '../../../assets';
import QuestionData from '../../../data/local/Question';
import PageLayout from '../../app/layout/PageLayout';
import useAssessment from './useAssessment';
import Option from '../../../data/local/Option';
import Button from '../../app/Button';
import AssessmentModules from './components/AssessmentModules';
import imageQ1 from '../../../assets/securityTraining/training_Q1.webp';
import imageQ5 from '../../../assets/securityTraining/training_Q5.webp';
import BackNavigationButton from '../../app/BackNavigationButton';
import ResultGood from '../../../assets/securityTraining/training_result_good.webp';
import ResultPoor from '../../../assets/securityTraining/training_result_poor.webp';

interface AssessmentProps {
  questions: QuestionData[];
  title: string;
  trainingId: number;
}

export default function Assessment({
  questions,
  title,
  trainingId,
}: AssessmentProps) {
  const {
    index,
    result,
    selectedAnswers,
    onNextClick,
    onPreviousClick,
    onOptionClick,
    error,
    resetToDefault,
    modulesData,
    isSuccess,
  } = useAssessment(questions, trainingId);

  return (
    <PageLayout
      leftPanel={
        <LeftPanelContent
          {...{
            index,
            error,
            selectedAnswers,
            onNextClick,
            onOptionClick,
            onPreviousClick,
            questions,
            title,
            result,
            resetToDefault,
          }}
        />
      }
      rightPanelContent={
        <RightPanelContent
          {...{
            question: questions[index],
            selectedOption: selectedAnswers.get(questions[index].id),
            onNextClick,
            onPreviousClick,
            onOptionClick,
            questionLength: questions.length,
            index,
            result,
            modulesData,
            isSuccess,
          }}
        />
      }
      filterBarContent={result && <FilterBarContent />}
      RightPanelFooter={result && <div />}
    />
  );
}

function LeftPanelContent({
  index,
  error,
  selectedAnswers,
  questions,
  onOptionClick,
  title,
  result,
  resetToDefault,
}: any) {
  const imageArray = [
    imageQ1,
    '',
    '',
    '',
    imageQ5,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ];
  const navigate = useNavigate();

  return (
    <div className='space-y-4 text-coolGray-800'>
      {!result ? (
        <>
          <BackNavigationButton
            onButtonClick={() => {
              navigate(-1);
            }}
            title={title}
          />
          <h1 className='page-heading-3'>Assessment</h1>
          <ProgressBar index={index} total={questions.length} />
          <div className='mb-1 text-base tracking-widest mt-1 font-medium text-black dark:text-white'>
            <p className='text-sm font-semibold'>
              Question {index + 1}/{questions.length}
            </p>{' '}
          </div>
          {!!error && (
            <div className='flex flex-row items-center pt-4 space-x-2'>
              <img className='w-5' src={Assets.svg.icError} alt='' />
              <p className='text-md font-bold text-red-500'>{error}</p>
            </div>
          )}
          <QuestionUI
            question={questions[index]}
            onOptionClick={onOptionClick}
            selectedOption={selectedAnswers.get(questions[index].id)}
          />
          {imageArray[index] && (
            <img src={imageArray[index]} alt='' className='w-full' />
          )}
        </>
      ) : (
        <>
          <h1 className='text-2xl font-medium'>
            Assessment Result: {result.score > 10 ? 'Pass' : 'Try again'}
          </h1>
          <img
            src={result.score > 10 ? ResultGood : ResultPoor}
            alt=''
            className='w-full'
          />
          <p className='text-md font-semibold text-gray-700'>
            You’ve answered {result.score}/{questions.length} questions
            correctly.
            <br />
          </p>
          <p className=''>
            {result.score > 10
              ? 'If you didn’t get every question correct, feel free to try again. If you are confident, you can test yourself on another topic.'
              : 'You didn’t get it this time, but don’t worry, we’re confident that you’ll get it soon. Review the materials at the beginning of the module and try the assessment again.'}
          </p>
          <div className='w-64'>
            <Button
              title='Back to e-learning material'
              onClick={() => navigate(-1)}
            />{' '}
          </div>
          <div className='w-64'>
            <Button
              title='Restart assessment'
              onClick={() => resetToDefault()}
              isGray
            />{' '}
          </div>
        </>
      )}
    </div>
  );
}

function FilterBarContent() {
  return <div>My modules</div>;
}

function RightPanelContent({
  index,
  onNextClick,
  onPreviousClick,
  question,
  questionLength,
  selectedOption,
  onOptionClick,
  result,
  modulesData,
  isSuccess,
}: any) {
  const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
  return (
    <>
      {!result ? (
        <div className='space-y-4 xl:px-1 xl:mt-20 '>
          <p className='text-sm text-gray-500'>Select answer</p>
          <div className='pb-4 sm:pb-0 space-y-6'>
            {question.options.map((option: Option, optIndex: number) => (
              <div
                key={option.id}
                onClick={() => onOptionClick(option)}
                className={`${
                  selectedOption === option.id &&
                  'bg-primary-900 transition-colors duration-600 font-medium'
                } flex items-center  border rounded p-3 cursor-pointer`}
              >
                <h1>{letters[optIndex]}</h1>

                <p className='ml-4 text-xs w-full '>{option.option}</p>
              </div>
            ))}
          </div>
          <Buttons
            next={onNextClick}
            prev={onPreviousClick}
            len={questionLength}
            index={index}
          />
        </div>
      ) : (
        <AssessmentModules isSuccess={isSuccess} data={modulesData} />
      )}
    </>
  );
}
