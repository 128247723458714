import React from 'react';
import { FormProvider } from 'react-hook-form';
import Button from '../../app/Button';
import FormError from '../../app/FormError';
import Input from '../../app/Input';
// import Container from '../signup/components/Container';
// import FormCard from '../signup/components/FormCard';
import useNewPassword from './useNewPassword';
import FormModal from '../../app/layout/FormModal';

export default function NewPassword() {
  const hook = useNewPassword();

  return (
    <FormModal
      heading='Set your new password'
      description='Please enter the verification code sent to your email and input a new password.'
    >
      <div className='space-y-10 w-full'>
        <FormProvider {...hook.form}>
          <div className='w-3/4 space-y-6'>
            <FormError error={hook.changePassword.error} />
            <Input type='text' name='code' label='Verification code' />
            <Input
              type='password'
              name='password'
              label='Password'
              isPasswordField
            />
            <Input
              type='password'
              name='confirmPassword'
              label='Confirm password'
              isPasswordField
            />
          </div>
        </FormProvider>

        <div className='w-1/2 space-y-6'>
          <Button
            type='submit'
            title='Set password'
            isLoading={hook.changePassword.isLoading}
            onClick={hook.onFormSubmit}
          />
          <Button
            type='button'
            title='Resend code'
            isGray
            isLoading={hook.resendPassword.isLoading}
            onClick={hook.onResendPassword}
          />
        </div>
      </div>
    </FormModal>
  );
}
