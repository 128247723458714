import { useMutation, useQuery } from 'react-query';
import ResendRequest from '../../data/requests/ResendRequest';
import SignInRequest from '../../data/requests/SignInRequest';
import SignUpRequest from '../../data/requests/SignUpRequest';
import UserRequest from '../../data/requests/UserRequest';
import VerifyRequest from '../../data/requests/VerifyRequest';
import { BaseErrorResponse } from '../../data/response/BaseErrorResponse';
import BaseResponse from '../../data/response/BaseResponse';
import SignInResponse from '../../data/response/SignInResponse';
import User from '../../data/local/User';
import axios from '../../utils/AxiosInterceptor';
import Constants from '../../utils/Constants';
import NewPasswordRequest from '../../data/requests/NewPasswordRequest';
import ReportBreachRequest from '../../data/requests/ReportBreach';

const signUp = async (request: SignUpRequest): Promise<BaseResponse<null>> => {
  const response = await axios(Constants.baseUrl).post(
    `/auth/sign_up`,
    request,
  );
  return response.data;
};

const login = async (
  request: SignInRequest,
): Promise<BaseResponse<SignInResponse>> => {
  const response = await axios(Constants.baseUrl).post(
    `/auth/sign_in`,
    request,
  );
  return response.data;
};

const verify = async (request: VerifyRequest): Promise<BaseResponse<null>> => {
  const response = await axios(Constants.baseUrl).post(
    `/auth/confirm`,
    request,
  );
  return response.data;
};

const resend = async (request: ResendRequest): Promise<BaseResponse<null>> => {
  const response = await axios(Constants.baseUrl).post(`/auth/resend`, request);
  return response.data;
};

const resetPassword = async (
  request: ResendRequest,
): Promise<BaseResponse<null>> => {
  const response = await axios(Constants.baseUrl).post(
    `/auth/reset-password`,
    request,
  );
  return response.data;
};

const changePassword = async (
  request: NewPasswordRequest,
): Promise<BaseResponse<null>> => {
  const response = await axios(Constants.baseUrl).post(
    `/auth/change-password`,
    request,
  );
  return response.data;
};

const getUser = async (): Promise<BaseResponse<User>> => {
  const response = await axios(Constants.baseUrl).get(`/user/settings`);
  return response.data;
};

const updateUser = async (
  request: UserRequest,
): Promise<BaseResponse<User>> => {
  const response = await axios(Constants.baseUrl).put(`/user/update`, request);
  return response.data;
};

const refreshToken = async (): Promise<BaseResponse<SignInResponse>> => {
  const response = await axios(Constants.baseUrl).post(`/auth/refresh-token`);
  return response.data;
};

const reportBreach = async (
  request: ReportBreachRequest,
): Promise<BaseResponse<null>> => {
  const response = await axios(Constants.baseUrl).post(
    `/report-breach`,
    request,
  );
  return response.data;
};

export const useSignUp = () =>
  useMutation<BaseResponse<null>, BaseErrorResponse, SignUpRequest>(signUp, {
    useErrorBoundary: false,
  });

export const useLogin = () =>
  useMutation<BaseResponse<SignInResponse>, BaseErrorResponse, SignInRequest>(
    login,
    {
      useErrorBoundary: false,
      retry: 0,
    },
  );

export const useVerify = () =>
  useMutation<BaseResponse<null>, BaseErrorResponse, VerifyRequest>(verify, {
    useErrorBoundary: false,
  });

export const useResend = () =>
  useMutation<BaseResponse<null>, BaseErrorResponse, ResendRequest>(resend, {
    retry: 0,
  });

export const useUpdateUser = () =>
  useMutation<BaseResponse<User>, BaseErrorResponse, UserRequest>(updateUser);

export const useRefreshToken = () =>
  useMutation<BaseResponse<SignInResponse>, BaseErrorResponse, null>(
    refreshToken,
  );

export const useFetchUser = () =>
  useQuery<BaseResponse<User>, BaseErrorResponse>(['user-profile'], getUser);

export const useResetPassword = () =>
  useMutation<any, BaseErrorResponse, ResendRequest>(resetPassword, {
    useErrorBoundary: false,
  });

export const useChangePassword = () =>
  useMutation<any, BaseErrorResponse, NewPasswordRequest>(changePassword, {
    useErrorBoundary: false,
  });

export const useReportBreach = () =>
  useMutation<BaseResponse<null>, BaseErrorResponse, ReportBreachRequest>(
    reportBreach,
  );
