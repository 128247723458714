import React from 'react';
import ComboBox from '../../../../app/ComboBox';
import Button from '../../../../app/Button';

interface ISearchCardProps {
  hook: any;
}

function SearchCard({ hook }: ISearchCardProps) {
  let heading =
    'Scan your leaked information online with our dark web monitoring service.';
  let paragraph =
    'The dark web is a hidden part of the internet where anonymity reigns and illicit activities take place. Our dark web monitor scans these hard-to-find databases and notifies you which sites have compromised your email and how to take immediate action.';
  let message =
    'Input your email or phone number below to check for any data breaches.';

  if (hook.summery.data?.data.length > 0) {
    heading =
      'Stop a data breach from becoming identity theft with 24/7 dark web monitoring.';
    paragraph =
      'Protect your important information and find out if it has been stolen and are being sold on the dark web. \n\n If we find your details, we will let you know immediately and guide you through the steps you need to take to protect your personal information.';
  }

  if (hook?.breach?.data?.data?.length === 0) {
    heading = `Good news! Your data is safe; We did not find any breaches associated with <span  style=" color: #3F3F75; overflow: hidden; text-overflow: ellipsis "  >${hook.form.watch(
      'search',
    )}</span>‎  ‎`;
    paragraph = 'Our dark web scan did not detect any breaches at this time.';
    message =
      'To check for breaches associated with another email or phone number, click the button below.';
  } else if (hook.filteredBreaches.length !== 0) {
    heading = `We've detected <span style='color: #3F3F75' >${
      hook?.breach?.data?.data?.length
    } breaches</span>  associated with <span style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; color: #3F3F75" >${hook.form.watch(
      'search',
    )}</span>‎  ‎`;
    paragraph =
      'Our dark web scan detected digital breaches associated with your account.';
    message =
      'To run another search or find out what you can do next to protect yourself, click the buttons below.';
  }
  return (
    <>
      <p className='page-heading '>Personal data breaches</p>
      <p
        className='page-description line-clamp-3 '
        dangerouslySetInnerHTML={{ __html: heading }}
      />
      <p className='text-xs  sm:text-sm  ' style={{ whiteSpace: 'pre-line' }}>
        {paragraph}
      </p>
      <p className='text-xs sm:text-sm mt-4'>{message}</p>
      <div className=''>
        {!hook.showEmailBreaches ? (
          <ComboBox
            value={hook.form.watch('search')}
            items={hook.emails.data?.data}
            placeholder='Enter email or phone number'
            buttonText='Scan for breaches'
            error={
              hook.form.formState.isSubmitted &&
              hook.form.formState.errors.search?.message
            }
            onChangeDebounce={value =>
              hook.form.setValue('search', value, { shouldValidate: true })
            }
            onButtonClick={hook.form.handleSubmit(hook.onSearch)}
          />
        ) : (
          <div className='min-w-[200px] w-max'>
            <Button
              type='button'
              onClick={() => {
                hook.form.reset();
                hook.breach.remove();
                hook.setShowEmailBreaches(false);
                hook.summery.refetch();
              }}
              title=' Run another search'
            />
          </div>
        )}
      </div>
    </>
  );
}

export default SearchCard;
