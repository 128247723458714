import { readCookie, setCookie } from './CookieManager';

const CookieUtils = {
  setUserToken(token: string, expiryInSecs = 3600) {
    setCookie('access_token', token, expiryInSecs);
  },
  getUserToken(): string {
    return readCookie('access_token') ?? '';
  },
  removeUserToken() {
    setCookie('access_token', '', -1);
  },

  setRefreshToken(token: string) {
    setCookie('refresh_token', token, 5184000); // 60 days in seconds
  },
  getRefreshToken(): string {
    return readCookie('refresh_token') ?? '';
  },
  removeRefreshToken() {
    setCookie('refresh_token', '', -1);
  },
};
export default CookieUtils;
