import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  useSearchPwned,
  useSearchEmail,
  useBreachSeen,
  usePwnedList,
} from '../../../../app/hooks/usePwned';
import { useFetchUser } from '../../../../app/hooks/useUser';
import {
  PwnedStatus,
  ReadStatus,
  sortByStatus,
} from '../../../../data/Enum/PwnedStatus';
import PwnedResponse from '../../../../data/response/PwnedResponse';
import Utils from '../../../../utils/Utils';
import { emailOrPhoneNumberValidation } from '../../../../utils/validations';

const schema = yup.object({
  search: emailOrPhoneNumberValidation,
  readFilter: yup.string().default('All'),
  filter: yup.string().default('All'),
  sortBy: yup.string().default('Date - New to old'),
});

type FormType = yup.InferType<typeof schema>;

export default function useDarkWeb() {
  const form = useForm<FormType>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    defaultValues: {
      search: '',
      readFilter: 'All',
      filter: 'All',
      sortBy: 'Date - New to old',
    },
  });

  const emails = useSearchEmail(form.formState.isValid, form.watch('search'));
  const breach = useSearchPwned(form.watch('search'));
  const user = useFetchUser();
  const seen = useBreachSeen();
  const summery = usePwnedList();

  const divRef = React.useRef<HTMLDivElement | null>(null);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [showEmailBreaches, setShowEmailBreaches] = React.useState(false);

  // React.useEffect(() => {
  //   if (form.watch('search') !== '') {
  //     form.trigger('search');
  //   } else {
  //     form.clearErrors('search');
  //   }
  //   return () => {};
  // }, [form.watch('search')]);

  React.useEffect(() => {
    if (emails.isError) {
      form.setError('search', { message: 'Invalid Input' });
    } else {
      form.clearErrors('search');
    }
    return () => {};
  }, [emails.isError]);
  // React.useEffect(() => {
  //   if (
  //     breach.data?.data.length &&
  //     !breach.data?.data.some(it => it.disableScroll)
  //   ) {
  //     const y = divRef.current?.getBoundingClientRect()?.bottom ?? 0;
  //     window.scrollTo({ top: y - 120, behavior: 'smooth' });
  //   }
  // }, [breach.data?.data]);

  const filteredBreaches = React.useMemo(() => {
    return Utils.filterBreaches(
      breach.data?.data ?? [],
      form.getValues('filter') as PwnedStatus,
      form.getValues('readFilter') as ReadStatus,
      form.getValues('sortBy') as sortByStatus,
    );
  }, [
    breach.data?.data,
    form.watch('filter'),
    form.watch('readFilter'),
    form.watch('sortBy'),
  ]);

  React.useEffect(() => {
    return () => {
      breach.remove();
    };
  }, []);

  // const sortByFilteredData = React.useMemo(() => {
  //   return Utils.sortByDate(
  //     breach.data?.data ?? [],
  //     form.getValues('sortBy') as sortByStatus,
  //   );
  // }, [breach.data?.data, form.watch('sortBy')]);

  const onSearch = async (_data: FormType) => {
    form.setValue('filter', 'All');
    form.setValue('readFilter', 'All');
    form.setValue('sortBy', 'Date - New to old');

    setShowEmailBreaches(true);
    await breach.refetch();
  };

  const onItemSeenClick = async (item: PwnedResponse) => {
    await seen.mutateAsync({
      breach_id: item.main_id.toString(),
      seen_id: item.id,
    });
  };

  return {
    divRef,
    form,
    breach,
    emails,
    filteredBreaches,
    user,
    modalVisible,
    showEmailBreaches,
    onSearch,
    setModalVisible,
    onItemSeenClick,
    setShowEmailBreaches,
    summery,
  };
}
