import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import {
  useChangePassword,
  useResetPassword as useResetMutation,
} from '../../../app/hooks/useUser';
import { codeValidation, passwordValidation } from '../../../utils/validations';

const schema = yup.object({
  code: codeValidation,
  password: passwordValidation,
  confirmPassword: passwordValidation.oneOf(
    [yup.ref('password')],
    'Passwords do not match',
  ),
});
type FormType = yup.InferType<typeof schema>;

export default function useNewPassword() {
  const changePassword = useChangePassword();
  const resendPassword = useResetMutation();
  // const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const form = useForm<FormType>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (form.watch('code') !== '') {
      changePassword.reset();
    }
    return () => {};
  }, [form.watch('code')]);

  useEffect(() => {
    if (changePassword.isError) {
      form.setValue('code', '');
    }
    return () => {};
  }, [changePassword.isError]);

  const onFormSubmit = async (data: FormType) => {
    await changePassword.mutateAsync({
      code: data.code,
      email: decodeURIComponent(searchParams.get('email') ?? ''),
      password: data.password,
    });
    toast.success('Password reset successfully');
    navigate('/signin', { replace: true });
  };

  const onResendPassword = async () => {
    await resendPassword.mutateAsync({
      email: decodeURIComponent(searchParams.get('email') ?? ''),
      app_id: '2XybYTuPwHu7dtWPuie4llHMgb1',
    });
    form.setValue('code', '');
    toast.success('Email has been sent with verification code');
    changePassword.reset();
  };

  return {
    form,
    changePassword,
    resendPassword,
    onFormSubmit: form.handleSubmit(onFormSubmit),
    onResendPassword,
  };
}
