import React from 'react';
import { Spinner } from 'flowbite-react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  onClick?: () => void;
  isLoading?: boolean;
  isGray?: boolean;
  isDisabled?: boolean;
}

export default function Button({
  isLoading,
  isGray = false,
  title,
  isDisabled = false,
  ...props
}: ButtonProps) {
  let formatting = 'bg-primary hover:bg-primary-700 text-coolGray-800';

  if (isGray)
    formatting = 'text-[#56565C] hover:bg-gray-50 border border-[#9090AF]';
  else formatting = 'bg-primary hover:bg-primary-700 text-coolGray-800';
  if (isDisabled) formatting = 'bg-[#F7F7F7] text-[#BDBDBF]';
  if (isGray && isDisabled)
    formatting = 'text-[#D6D6D9] border border-[#D6D6D9] bg-white  ';
  return (
    <button
      type='button'
      onClick={e => {
        e.preventDefault();
        props.onClick?.();
      }}
      disabled={isDisabled || isLoading}
      className={`${formatting} appearance-none w-full flex justify-center tracking-wide items-center h-10 py-2 px-4  rounded-md shadow-sm text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
      {...props}
    >
      {isLoading && (
        <Spinner
          color='warning'
          className='mr-2'
          aria-hidden='true'
          aria-label='Left-aligned spinner example'
        />
      )}
      {title}
    </button>
  );
}
