/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import Assets from '../../assets';

interface IBackButtonNavigationProps {
  title: string;
  onButtonClick: () => void;
}
function BackNavigationButton({
  title,
  onButtonClick,
}: IBackButtonNavigationProps) {
  return (
    <div className='flex space-x-4 justify-start items-center'>
      <img
        src={Assets.svg.icBackArrowButton}
        alt='back_button'
        onClick={onButtonClick}
        className=' cursor-pointer'
      />
      <p className='text-sm text-gray-600'>Back to {title}</p>
    </div>
  );
}

export default BackNavigationButton;
