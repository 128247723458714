import React from 'react';
import PageLayout from '../../app/layout/PageLayout';
import AssessmentModules from './components/AssessmentModules';
import useAssessment from './useAssessment';

export default function Landing() {
  return (
    <PageLayout
      leftPanel={<LeftPanelContent />}
      rightPanelContent={<RightPanelContent />}
      filterBarContent={<FilterContent />}
      RightPanelFooter={<div />}
    />
  );
}

function LeftPanelContent() {
  return (
    <div className=''>
      <h1 className='page-heading pb-2'>Security Training</h1>
      <p className='page-description pb-5'>
        Learn about and test your knowledge on all things cyber security
        related.
      </p>
      <p className='text-xs  sm:text-sm '>
        This comprehensive program is designed to equip you with the essential
        knowledge and skills to protect yourself and your digital assets from
        cyber threats. Our carefully crafted modules cover a wide range of
        topics, from the basics of cyber security to advanced techniques for
        recognizing and mitigating potential risks.
      </p>
      <p className='text-xs  sm:text-sm  mt-4'>
        By the end of the assessment, you will have a deep understanding of
        common cyber threats, secure password practices, safe web browsing,
        mobile device security, and the art of defending against social
        engineering attacks.{' '}
      </p>
    </div>
  );
}

function FilterContent() {
  return (
    <div className='flex items-center'>
      <p>My modules</p>
    </div>
  );
}

function RightPanelContent() {
  const { isSuccess, modulesData } = useAssessment([], 1);
  return <AssessmentModules isSuccess={isSuccess} data={modulesData} />;
}
