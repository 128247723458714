import React from 'react';
import { Link } from 'react-router-dom';
import Assets from '../../../../../assets';

interface DashboardTilesProps {
  title: string;
  description: string;
  image: string;
  forwardLink: string;
  newTab?: boolean;
}

function DashboardTiles({
  title,
  description,
  image,
  forwardLink,
  newTab = false,
}: DashboardTilesProps) {
  return (
    <div className='p-6 w-72 lg:p-0  lg:w-[13.5rem]  xl:tall:w-72 2xl:tall:w-80 flex-col tall:space-y-1'>
      <div className=''>
        <img src={image} alt='' className='w-full' />
      </div>
      <div className='p-1 pt-2 space-y-1 tall:space-y-2'>
        <div className=' flex flex-row justify-between'>
          <h2
            className='font-open-sans text-sm text-zinc-700 font-semibold '
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Link to={forwardLink} target={newTab ? '_blank' : ''}>
            <img src={Assets.svg.icArrow} alt='' className='' />
          </Link>
        </div>

        <hr className='border border-zinc-200' />

        <div
          className='text-xs text-[#616162]'
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  );
}

export default DashboardTiles;
