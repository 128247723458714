import { Spinner } from 'flowbite-react';
import React from 'react';

export default function Redirecting() {
  return (
    <div className='mx-auto flex items-center flex-col mt-10'>
      <Spinner aria-label='Default status example' />
      <h1 className='text-2xl'>Redirecting...</h1>
    </div>
  );
}
