import React from 'react';
import { Link } from 'react-router-dom';
import Assets from '../../../assets';

export default function ComingSoon() {
  return (
    <div className='h-full w-full overflow-hidden overflow-y-auto'>
      <div className='flex flex-col justify-center items-center p-10 text-base'>
        <img
          src={Assets.svg.icComingSoon}
          alt='coming ComingSoon'
          className='self-center'
        />{' '}
        <h1 className='text-red-black text-center  mt-5'>
          This module is under development. Please check Back later.
        </h1>
        <div className='flex justify-center p-4'>
          <Link
            to='/dashboard'
            className='pr-2 pl-2 p-1 rounded-lg border-2 text-base border-primary-500 font-medium bg-white'
          >
            Go to Dashboard
          </Link>
        </div>
      </div>
    </div>
  );
}
