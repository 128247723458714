/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import {
  QueryClient,
  QueryClientProvider,
  QueryErrorResetBoundary,
} from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import AuthProvider, { useAuth } from './app/contexts/AuthContext';
import MainRouter from './app/routers/MainRoute';
import CookieUtils from './utils/CookieUtils';
import ErrorPage from './components/pages/errorpage';
import Toast from './components/app/Toast';

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      useErrorBoundary: true,
    },
    queries: {
      useErrorBoundary: true,
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  return (
    <AuthProvider token={CookieUtils.getUserToken()}>
      <QueryClientProvider client={queryClient}>
        <QueryBoundaries>
          <MainRouter />
        </QueryBoundaries>
        <Toast />
      </QueryClientProvider>
    </AuthProvider>
  );
}

export function QueryBoundaries({ children }: { children: React.ReactNode }) {
  const auth = useAuth();
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          FallbackComponent={props => {
            if (props.error.code === '406') auth?.handleLogout();

            return (
              <ErrorPage
                error={
                  props.error?.message
                    ? props.error
                    : new Error('something went wrong')
                }
                onRefreshClick={props.resetErrorBoundary}
              />
            );
          }}
        >
          {/* <React.Suspense fallback={<LoadingView />}> */}
          {children}
          {/* </React.Suspense> */}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
