/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PageLayout from '../../app/layout/PageLayout';
import ContactCard from './components/ContactCard';
import cardsData from '../../../data/local/page_data/emergency_room/landing-page-right-panel-tiles';
import DataTile from '../../app/DataTile';

function EmergencyRoom() {
  const [inputText, setInputText] = React.useState('');
  const inputHandler = (e: any) => {
    e.preventDefault();
    // convert input text to lower case
    // const lowerCase = e.target.value;
    setInputText(e.target.value);
  };
  return (
    <PageLayout
      leftPanel={<LeftPanelContent />}
      filterBarContent={<RightPanelFilter />}
      rightPanelContent={<RightPanelContent inputText={inputText} />}
      RightPanelFooter={<RightPanelFooter />}
      RightPanelSearchBar={
        <RightPanelSearchBar
          inputText={inputText}
          inputHandler={inputHandler}
        />
      }
    />
  );
}

export default EmergencyRoom;

function LeftPanelContent() {
  return (
    <div className='space-y-3'>
      <h1 className='page-heading'>Emergency Support</h1>
      <p className='page-description'>
        Need help with a cyber emergency? Our team is here for you.
      </p>
      <p className='text-xs  sm:text-sm  py-3'>
        If you suspect a cyber attack, contact our expert team immediately to
        limit potential harm. You can also consult our resources section for
        in-depth guidance.
      </p>
      <div className='max-w-[450px] space-y-4'>
        <ContactCard header='Report by phone' phone='1-888-349-6660' />
        <ContactCard
          header='Report by email'
          email='hackbusters@boxxinsurance.com'
        />
      </div>
    </div>
  );
}

function RightPanelContent({ inputText }: { inputText: string }) {
  // create a new array by filtering the original array
  const filteredData = cardsData.filter(el => {
    // if no input the return the original
    if (inputText === '') {
      return el;
    }
    // return the item which contains the user input

    return (
      el.title.toLowerCase().includes(inputText.toLowerCase()) ||
      el.description.toLowerCase().includes(inputText.toLowerCase())
    );
  });
  return (
    <div className='space-y-4'>
      {/* <RightPanelSearchBar inputText={inputText} inputHandler={inputHandler} /> */}
      {filteredData.map(item => (
        <DataTile
          key={item.description}
          image={item.image}
          heading={item.title}
          description={item.description}
          navigateTo={item.NavigateTo}
        />
      ))}
    </div>
  );
}
function RightPanelSearchBar(props: any) {
  return (
    <form
      className='m-1'
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <label
        htmlFor='default-search'
        className='mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white'
      >
        Search
      </label>
      <div className='relative'>
        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
          <svg
            className='w-4 h-4 text-[#C3C4C7] dark:text-gray-400'
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 20 20'
          >
            <path
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
            />
          </svg>
        </div>
        <input
          type='search'
          id='default-search'
          className='block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
          placeholder='Search articles by keyword'
          onChange={props.inputHandler}
          value={props.inputText}
          // required
        />
      </div>
    </form>
  );
}

function RightPanelFilter() {
  return <div>Our resources</div>;
}

function RightPanelFooter() {
  return <div />;
}
