/* eslint-disable no-unused-expressions */
import { useSearchParams } from 'react-router-dom';
import { useSubscription } from '../../../app/hooks/useSubscription';
import subscriptionRequest from '../../../data/requests/subscriptionRequest';
import { emailValidation } from '../../../utils/validations';

export default function useEmailSubscriptions() {
  const subscribe = useSubscription();
  const [searchParams] = useSearchParams();
  const email = decodeURIComponent(searchParams.get('email') ?? '');

  const handleSubmit = async () => {
    if (!email) return;
    try {
      // Validate email using Yup schema
      await emailValidation.validate(email);

      // If validation passes, proceed with the subscription
      await subscribe.mutateAsync({ email } as subscriptionRequest);
    } catch (error: any) {
      /* empty */
    }
  };
  return {
    handleSubmit,
    email,
    subscribe,
  };
}
