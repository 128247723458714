import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';
import CookieUtils from '../../utils/CookieUtils';

interface AuthType {
  token: string;
  isVerified: boolean;
  handleLogin: (token: string) => void;
  handleLogout: () => void;
  setVerificationTrue: () => void;
  setVerificationFalse: () => void;
}

const AuthContext = createContext<AuthType | null>(null);

interface AuthProviderProps extends PropsWithChildren {
  token: string;
}

export default function AuthProvider(props: AuthProviderProps) {
  const [token, setToken] = React.useState(props.token);
  const [isVerified, setIsVerified] = React.useState(false);

  React.useEffect(() => {
    if (window?.StonlyWidget && token) {
      console.log('haider');
      window?.StonlyWidget('identify', token);
    }
    return () => {};
  }, [token]);

  const handleLogin = (newToken: string) => {
    setToken(newToken);
  };

  const handleLogout = () => {
    setToken('');
    CookieUtils.removeUserToken();
    CookieUtils.removeRefreshToken();
    setIsVerified(false);
  };

  const setVerificationTrue = () => {
    setIsVerified(true);
  };

  const setVerificationFalse = () => {
    setIsVerified(false);
  };

  const memo = useMemo(
    () => ({
      token,
      handleLogin,
      handleLogout,
      isVerified,
      setVerificationTrue,
      setVerificationFalse,
    }),
    [token, isVerified],
  );

  return (
    <AuthContext.Provider value={memo}>{props.children}</AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
