import React from 'react';
import Assets from '../../../../assets';

function ContactCard({
  header,
  phone,
  email,
}: {
  header: string;
  phone?: string;
  email?: string;
}) {
  return (
    <div className='border-2 rounded-xl border-[#FCDA97] p-4 flex flex-col space-y-3 bg-[#FFFDF9]'>
      <h1 className='text-base font-medium'>{header}</h1>
      {phone && (
        <p className='text-sm flex space-x-2 items-center'>
          <span className='rounded-full p-2 bg-[#FCEBCA]'>
            <img src={Assets.svg.icPhone} alt='phone_logo' />
          </span>
          <span className='underline underline-offset-2 text-[#333334]'>
            <a href={`tel:${phone}`} className='appearance-none text-inherit'>
              {phone}
            </a>
          </span>
        </p>
      )}
      {email && (
        <p className='text-sm flex space-x-2 items-center'>
          <span className='rounded-full p-2 bg-[#FCEBCA]'>
            <img src={Assets.svg.icMail} alt='mail_logo' />
          </span>
          <span className='underline underline-offset-2 text-[#333334]'>
            <a
              href={`mailto:${email}`}
              className='appearance-none text-inherit'
            >
              {email}
            </a>
          </span>
        </p>
      )}
    </div>
  );
}

export default ContactCard;
