import React, { Fragment } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import Utils from '../../utils/Utils';
import Button from './Button';

interface ComboBoxProps {
  items: string[] | undefined;
  value: string;
  placeholder: string;
  buttonText: string;
  error: string | undefined;
  onButtonClick: () => void;
  onChangeDebounce: (value: string) => void;
  isDisabled?: boolean;
}

export default function ComboBox(props: ComboBoxProps) {
  const searchCallback = React.useCallback(
    Utils.debounce(props.onChangeDebounce, 400),
    [],
  );

  return (
    <div className='z-10'>
      <div className='flex flex-col space-y-4  '>
        <div className='w-full z-10'>
          <Combobox value={props.value} onChange={searchCallback}>
            <div className='relative flex-1'>
              <Combobox.Input
                className='w-full border-1 rounded-lg text-xs sm:text-sm bg-neutral-50 border-gray-200  placeholder-gray-400 '
                defaultValue=''
                placeholder={props.placeholder}
                onChange={event => {
                  searchCallback(event.target.value);
                }}
              />
              {/* <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
                <ChevronUpDownIcon
                  className='h-5 w-5 text-gray-400'
                  aria-hidden='true'
                />
              </Combobox.Button> */}

              <Transition
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
                // afterLeave={() => setQuery('')}
              >
                <Combobox.Options className='absolute mt-1 max-h-60 w-full z-50 overflow-auto rounded-md bg-white  text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                  {' '}
                  {props.items?.length !== 0 &&
                    props.value !== '' &&
                    props.items?.map(item => (
                      <Combobox.Option
                        key={item}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active ? 'bg-primary text-white' : 'text-gray-900'
                          }`
                        }
                        value={item}
                      >
                        {({ selected, active }) => (
                          <>
                            {selected ? (
                              <span
                                className={`absolute inset-y-0 right-0 flex items-center pr-3 ${
                                  active ? 'text-white' : 'text-primary'
                                }`}
                              >
                                <CheckIcon
                                  className='h-5 w-5'
                                  aria-hidden='true'
                                />
                              </span>
                            ) : null}
                            <span
                              className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              {item}
                            </span>
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
          {props.error && (
            <p className='text-sm p-1 text-red-400'>{props.error}</p>
          )}
        </div>
        <div className='min-w-[200px] w-max'>
          <Button
            type='button'
            onClick={props.onButtonClick}
            // className='min-w-[160px] sm:min-w-[176px] w-max  text-coolGray-800 z-[5] px-6 py-2 sm:py-1 text-center xl:self-start xl:mr-2 rounded-lg text-xs sm:text-base font-medium  bg-primary'
            isDisabled={props.isDisabled}
            title={props.buttonText}
          />
        </div>
      </div>
    </div>
  );
}

ComboBox.defaultProps = {
  placeholder: '',
  buttonText: '',
  error: '',
};
