import React from 'react';

export default function SummerySkeleton() {
  return (
    <div className='flex flex-col relative shadow-xl rounded-lg justify-items-center mx-auto lg:w-10/12 overflow-clip duration-100  animate-pulse'>
      <div className='w-full bg-gray-200 py-1 bg-clip-border'>
        <div className='w-1/3 bg-gray-300 mx-auto rounded-md text-black h-6 bg-clip-border' />
        <div className='absolute right-2 bg-gray-300 rounded-md top-1 h-6 w-6' />
      </div>

      <div className='grid grid-cols-2 md:grid-cols-3 py-2 px-10'>
        <div className='col-span-1 w-4/5 flex flex-col flex-1 items-center xl:items-start'>
          <div className='w-full h-6 bg-gray-200 rounded-md' />
          <div className='w-full h-10 mt-2 bg-gray-200 rounded-md' />
        </div>

        <div className='col-span-1 w-4/5 flex flex-col flex-1 items-center xl:items-start'>
          <div className='w-full h-6 bg-gray-200 rounded-md' />
          <div className='w-full h-10 mt-2 bg-gray-200 rounded-md' />
        </div>

        <div className='col-span-3 md:col-span-1 mb-4 flex flex-row shadow-lg shadow-zinc-400 space-x-2 px-4 rounded-xl bg-white mx-auto items-center'>
          <div className='w-24 h-6 bg-gray-200 rounded-md' />
          <div className='h-7 w-7 bg-gray-200 rounded-full' />
        </div>
      </div>
    </div>
  );
}
