import { useEffect, useState } from 'react';
import Option from '../../../data/local/Option';
import Question from '../../../data/local/Question';
import {
  useAssessmentScore,
  useSaveTrainingScore,
} from '../../../app/hooks/useTrainingAssessment';

type Answer = {
  total: number;
  score: number;
  failed: number;
} | null;

interface IAssessmentDetails {
  description: string;
  image: string;
  onClickNavigateTo: string;
  title: string;
  score: number | null;
}

export default function useAssessment(
  questions: Question[],
  trainingId: number,
) {
  const [index, setIndex] = useState(0);
  const [error, setError] = useState('');
  const [selectedAnswers, setSelectedAnswers] = useState<Map<string, string>>(
    new Map(),
  );
  const [result, setResult] = useState<Answer>(null);
  const postResult = useSaveTrainingScore();
  const getScore = useAssessmentScore();

  useEffect(() => {
    if (postResult.isSuccess) {
      getScore.refetch();
    }
    return () => {};
  }, [postResult.isSuccess]);

  useEffect(() => {
    if (result) {
      postResult.mutateAsync({
        training_id: trainingId,
        score: result.score,
      });
    }
    return () => {};
  }, [result]);

  const onNextClick = () => {
    setError(() => '');
    if (validateCurrent()) {
      if (selectedAnswers.has(questions[index].id)) {
        if (index < questions.length - 1) setIndex(index + 1);
        else calculateResult();
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setError('Please select an option!');
    }
  };

  const onPreviousClick = () => {
    if (index !== 0) {
      setError(() => '');
      setIndex(index - 1);
    }
  };

  const validateCurrent = () => {
    return !!selectedAnswers.get(questions[index].id);
  };

  const onOptionClick = (option: Option) => {
    const { id } = questions[index];
    setSelectedAnswers(new Map(selectedAnswers.set(id, option.id)));
  };

  const calculateResult = () => {
    let success = 0;
    selectedAnswers.forEach((value, key) => {
      if (questions.find(it => it.id === key)?.answer === value) success += 1;
    });
    setResult({
      score: success,
      total: questions.length,
      failed: questions.length - success,
    });
  };

  const resetToDefault = () => {
    setIndex(0);
    setResult(null);
    setSelectedAnswers(new Map());
  };

  return {
    index,
    result,
    selectedAnswers,
    onNextClick,
    onPreviousClick,
    onOptionClick,
    error,
    resetToDefault,
    modulesData: getScore.data || ([] as unknown as IAssessmentDetails[]),
    isSuccess: !getScore.isLoading && !postResult.isLoading,
  };
}
