import React from 'react';
import Button from '../../app/Button';

interface ButtonsProps {
  next: () => void;
  prev: () => void;
  len: number;
  index: number;
}

export default function Buttons(props: ButtonsProps) {
  return (
    <div className='flex flex-row justify-between mt-10'>
      {props.index > 0 && (
        <div className='w-36 lg:w-52  min-w-[160px]'>
          <Button isGray title='Previous' onClick={props.prev} />
        </div>
      )}
      <div className='w-40 lg:w-52 min-w-[160px]'>
        <Button
          title={
            props.index === props.len - 1
              ? 'Submit Assessment'
              : 'Next Question'
          }
          onClick={props.next}
        />
      </div>
    </div>
  );
}
