import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Button from '../../app/Button';
import FormError from '../../app/FormError';
import Input from '../../app/Input';
import useResetPassword from './useResetPassword';
import FormModal from '../../app/layout/FormModal';

export default function ResetPassword() {
  const { form, reset, onFormSubmit } = useResetPassword();
  return (
    <FormModal
      heading='Reset your password'
      description='Enter your email to reset your password.'
    >
      <div className='space-y-6 w-full'>
        <FormProvider {...form}>
          <FormError error={reset.error} />
          <div className='w-full sm:w-3/4'>
            <Input label='Email' type='email' name='email' />
          </div>
        </FormProvider>
        <div className='w-[230px] space-y-2'>
          <Button
            onClick={onFormSubmit}
            title='Send email'
            isLoading={reset.isLoading}
          />
          <p className='text-xs sm:text-sm text-start'>
            or return to the{' '}
            <Link to='/signin' className='self-start  '>
              <span className='underline'>login page</span>
            </Link>
          </p>
        </div>
      </div>
    </FormModal>
  );
}
