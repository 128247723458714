/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */

import { Spinner } from 'flowbite-react';
import React from 'react';
import Assets from '../../assets';
import Utils from '../../utils/Utils';

interface SummeryItemProps {
  identity: string;
  leftHeading: string;
  rightHeading: string;
  last_week_results: string;
  this_week: string;
  buttonTitle: string;
  isDeleteLoading: boolean;
  lastBreachDate: string | null;
  onButtonClick: () => void;
  onDeleteClick: () => void;
}

export default function SummeryItemCard(props: SummeryItemProps) {
  const handleClick = () => {
    if (
      props.last_week_results.toString() === '0' &&
      props.this_week.toString() === '0'
    ) {
      /* empty */
    } else {
      props.onButtonClick();
    }
  };
  return (
    <div className='relative '>
      <button
        title='delete'
        type='button'
        onClick={props.onDeleteClick}
        disabled={props.isDeleteLoading}
      >
        <img
          className='absolute right-3 top-9 w-4  md:w-5 z-[5]'
          src={Assets.svg.icDelete}
          alt=''
        />
      </button>

      <div
        className={` bg-white flex flex-col border-2 relative shadow-lg rounded-lg justify-items-center mx-auto overflow-clip ${
          props.isDeleteLoading && 'animate-blur pointer-events-none z-20'
        }`}
        onClick={handleClick}
      >
        {/* <span className='w-full text-start p-2 md:pl-10 truncate text-sm bg-gray-50 text-black py-1 font-medium md:text-lg'>
          {props.identity}
        </span> */}
        {/* <span className='absolute right-2 top-2 px-3 text-center bg-red-600 text-white py-1 text-[0.7rem] rounded-full font-normal bg-clip-border'>
        Delete
      </span> */}
        <div className='space-y-1 md:space-y-0 grid grid-cols-2 xl:grid-cols-6 py-2 xl:py-5  px-3 items-center bg-white '>
          <div className='col-start-1 h-full col-span-5 xl:col-span-2 items-start pr-2 space-y-1 truncate'>
            <a
              href='/'
              title={props.identity}
              onClick={e => {
                e.preventDefault();
              }}
              className={`${
                props.last_week_results.toString() === '0' &&
                props.this_week.toString() === '0'
                  ? ' '
                  : ' hover:underline '
              }  sm:font-medium text-sm md:text-base   underline-offset-4 truncate'`}
            >
              {props.identity}
            </a>
            {props.lastBreachDate &&
              (props.last_week_results.toString() !== '0' ||
                props.this_week.toString() !== '0') && (
                <p className='text-xs xl:text-[10px] 2xl:text-sm text-gray-400'>
                  <span title={Utils.getFormattedDate(props.lastBreachDate)}>
                    Last breach detected:{' '}
                    {Utils.getFormattedDate(props.lastBreachDate)}{' '}
                  </span>
                </p>
              )}

            {/* <span>
              {props.lastbreachdate}
            </span> */}
          </div>
          <div className='xl::col-start-3 xl:col-span-2 col-span-1 flex flex-col flex-1 justify-center items-center'>
            <span className='text-lg md:text-2xl w-fit p-1 text-center font-medium text-[#3F3F75]'>
              {props.this_week}
            </span>

            <span className='w-fit font-normal text-xs tall:text-sm  text-center '>
              {props.leftHeading}
            </span>
          </div>
          <div className='xl::col-start-5 xl:col-span-2 col-span-1 flex flex-col flex-1 justify-center items-center'>
            <span className='text-lg md:text-2xl w-fit text-center font-medium p-1 text-[#3F3F75]'>
              {props.last_week_results}
            </span>

            <span className='w-fit text-center text-xs tall:text-sm  font-normal pr-1 '>
              {props.rightHeading}
            </span>
          </div>
          {/* <div className='hidden bg-red-600 xl:inline-flex flex-1' /> */}
          {/* <button
            className='border-2 border-solid  rounded-md border-gray-400 col-span-3 md:col-span-1 flex flex-row  shadow-zinc-400 space-x-2 px-4 py-1 bg-white w-fit items-center md:justify-self-end justify-self-center'
            type='button'
            onClick={props.onButtonClick}
          >
            <div className='text-black text-center'>{props.buttonTitle}</div>

          </button> */}
        </div>
      </div>
      {props.isDeleteLoading && (
        <Spinner
          light={false}
          color='warning'
          className='absolute top-1/2 left-1/2 w-6'
          aria-hidden='true'
          aria-label='Left-aligned spinner example'
        />
      )}
    </div>
  );
}
