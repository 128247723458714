/* eslint-disable react/function-component-definition */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/no-danger */
import React, { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { CheckIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import Assets from '../../../../assets';
import PwnedResponse from '../../../../data/response/PwnedResponse';
import Utils from '../../../../utils/Utils';

interface BreachItemProps {
  item: PwnedResponse;
  onProtectClick: () => void;
  onSeenClick: () => void;
}

export default function BreachItem(props: BreachItemProps) {
  // const isSeen = React.useMemo(() => {
  //   return !!props.item.id;
  // }, [props.item.id]);

  const isRead = props.item.user_id;
  return (
    <div className='flex flex-row mx-auto space-x-2 w-full'>
      {/* <input
        id={props.item.BreachDate}
        type='checkbox'
        checked={isSeen}
        onChange={props.onSeenClick}
        className='col-span-6 justify-self-end self-center text-xs font-medium rounded w-4 h-4 text-primary bg-white focus:ring-0 focus:ring-transparent checked:focus:ring-transparent dark:bg-gray-700 dark:border-gray-600'
      /> */}
      <div
        className={` w-full grid grid-cols-12 shadow-xl rounded-lg mx-auto p-3 md:p-6 gap-4 text-black border-2  ${
          !isRead ? 'border-primary-200 bg-primary-100' : 'border-white'
        }`}
      >
        <div className='grid col-span-12'>
          <div className='col-span-1 flex items-center '>
            <div className='flex justify-center items-center bg-gray w-[40px] h-[40px]'>
              {props.item.LogoPath ? (
                <img
                  className='w-[40px] max-h-[40px] '
                  src={props.item.LogoPath}
                  alt='logo'
                />
              ) : (
                <div className='w-full h-full bg-slate-100 font-medium text-black text-center flex justify-center items-center'>
                  <span>{props.item.name.charAt(0).toUpperCase()}</span>
                </div>
              )}
            </div>
            <h1 className='px-2 font-medium '>{props.item.name} </h1>
          </div>

          <div className='col-span-1 col-start-12 flex items-center justify-end'>
            {props.item.statusName === 'New' && (
              <span className='col-span-1 w-max h-max  col-start-11 bg-red-700 text-white text-xs font-medium  px-2.5 py-0.5 rounded  dark:bg-red-900 dark:text-red-300'>
                NEW
              </span>
            )}

            {props.item.statusName === 'Removed' && (
              <span className='col-span-1 col-start-11 text-white bg-green-700 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300'>
                Removed
              </span>
            )}
            <SeenBreachDropDown onClick={props.onSeenClick} hook={isRead} />
          </div>
        </div>

        {props.item.DataClasses && (
          <div className='col-span-12  text-[1.0em] text-base font-normal'>
            Compromised data:
            <br />
            {Utils.getStringArray(props.item.DataClasses).join(', ')}
            <br />
            <br />
            <ReadMoreLessParagraph
              htmlContent={props.item.Description}
              maxLength={120}
            />
            {/* <div dangerouslySetInnerHTML={{ __html: props.item.Description }} /> */}
          </div>
        )}
        <div className='col-span-12 flex py-1'>
          <img src={Assets.svg.DarkWeb_Calendar} alt='calendar' />
          <p className='text-xs text-gray-500 px-2'>
            {props.item.BreachDate
              ? Utils.getFormattedDate(props.item.BreachDate)
              : '-'}{' '}
          </p>
        </div>

        {/* <button
          className='col-span-12 flex flex-row items-center md:col-span-4 w-fit md:col-start-3 font-semibold text-[0.7rem]'
          type='button'
          onClick={props.onProtectClick}
        >
          HOW TO PROTECT YOUR DATA
          <img className='w-5 ml-2' src={Assets.svg.icRightArrow} alt='' />
        </button> */}
      </div>
    </div>
  );
}

function SeenBreachDropDown({
  onClick,
  hook,
}: {
  onClick: () => void;
  hook: any;
}) {
  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <Menu.Button className='inline-flex w-full justify-center rounded-md  py-2 text-sm font-medium text-black hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-opacity-75'>
          <EllipsisVerticalIcon
            className='ml-2 -mr-1 h-5 w-5 text-black  '
            aria-hidden='true'
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='z-10 absolute right-0  w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='px-1 py-1 bg-gray-50'>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => {
                    if (!hook) onClick();
                  }}
                  className={`${
                    active ? 'bg-gray-500 text-white' : 'text-gray-900'
                  } group flex w-full items-center rounded-md px-2 py-2 text-xs sm:text-sm justify-between`}
                >
                  <span>Mark as [read]</span>
                  <span>{hook && <CheckIcon className='ml-2 h-4 w-4 ' />}</span>
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => {
                    if (hook) onClick();
                  }}
                  className={`${
                    active ? 'bg-gray-500 text-white' : 'text-gray-900'
                  } group flex w-full items-center rounded-md px-2 py-2 text-xs sm:text-sm justify-between`}
                >
                  <span>Mark as [un-read]</span>
                  <span>
                    {!hook && <CheckIcon className='ml-2 h-4 w-4 ' />}
                  </span>
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

interface ReadMoreLessParagraphProps {
  htmlContent: string;
  maxLength: number;
}

const ReadMoreLessParagraph: React.FC<ReadMoreLessParagraphProps> = ({
  htmlContent,
  maxLength,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  // Function to strip HTML tags and return plain text
  const stripHTMLTags = (html: string) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || '';
  };

  // Truncate the plain text
  const truncatedText = stripHTMLTags(htmlContent).slice(0, maxLength);

  return (
    <p>
      {isExpanded ? stripHTMLTags(htmlContent) : truncatedText}
      {stripHTMLTags(htmlContent).length > maxLength && (
        <span
          onClick={toggleReadMore}
          style={{ color: 'blue', cursor: 'pointer' }}
        >
          {isExpanded ? ' Read less' : '... Read more'}
        </span>
      )}
    </p>
  );
};
