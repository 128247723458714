/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useController } from 'react-hook-form';

interface SwitchProps {
  label: string;
  name: string;
}
export default function Switch(props: SwitchProps) {
  const fields = useController({
    name: props.name,
    defaultValue: false,
  });

  return (
    <div className='col-span-2 mt-2'>
      <label className='relative inline-flex items-center cursor-pointer'>
        <input
          type='checkbox'
          checked={fields.field.value}
          onChange={e => fields.field.onChange(e.target.checked)}
          className='w-4 h-4 text-[#9090AF] rounded-md bg-white  focus:ring-[#9090AF] dark:focus:ring-primary dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
        />
        <span className='ml-3 text-sm font-medium text-gray-900 dark:text-gray-300'>
          Subscribe to weekly emails
        </span>
      </label>
    </div>
  );
}
