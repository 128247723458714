/* eslint-disable import/prefer-default-export */
import { useMutation } from 'react-query';
import subscriptionRequest from '../../data/requests/subscriptionRequest';
import { BaseErrorResponse } from '../../data/response/BaseErrorResponse';
import BaseResponse from '../../data/response/BaseResponse';
import axios from '../../utils/AxiosInterceptor';
import Constants from '../../utils/Constants';

const subscribe = async (
  request: subscriptionRequest,
): Promise<BaseResponse<any>> => {
  const response = await axios(Constants.baseUrl).post('/unsubscribe', request);
  return response.data;
};

export const useSubscription = () =>
  useMutation<BaseResponse<any>, BaseErrorResponse, subscriptionRequest>(
    subscribe,
    {
      useErrorBoundary: false,
    },
  );
