import React from 'react';
import { Spinner } from 'flowbite-react';
import DataTile from '../../../app/DataTile';

interface IAssessmentDetails {
  description: string;
  image: string;
  onClickNavigateTo: string;
  title: string;
  score: number | null;
}

function AssessmentModules({
  isSuccess,
  data,
}: {
  isSuccess: boolean;
  data: IAssessmentDetails[];
}) {
  if (isSuccess) {
    return (
      <div className='space-y-4'>
        {data.map(item => (
          <DataTile
            key={item.description}
            image={item.image}
            heading={item.title}
            navigateTo={item.onClickNavigateTo}
            description={item.description}
            score={item.score}
          />
        ))}
      </div>
    );
  }
  return (
    <div className='w-full h-full flex justify-center items-center'>
      <Spinner />
    </div>
  );
}

export default AssessmentModules;
