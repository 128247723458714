/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useMutation,
  useQuery,
  QueryFunctionContext,
  useQueryClient,
} from 'react-query';
import SeenRequest from '../../data/requests/SeenRequest';
import DeleteEmailRequest from '../../data/requests/DeleteEmailRequest';
import { BaseErrorResponse } from '../../data/response/BaseErrorResponse';
import BaseResponse from '../../data/response/BaseResponse';
import PwnedListResponse from '../../data/response/PwnedListResponse';
import PwnedResponse from '../../data/response/PwnedResponse';
import axios from '../../utils/AxiosInterceptor';
import Constants from '../../utils/Constants';
import Utils from '../../utils/Utils';
import User from '../../data/local/User';
import SeenResponse from '../../data/response/SeenResponse';

const searchPwned = async (
  query: QueryFunctionContext<string[]>,
): Promise<BaseResponse<PwnedResponse[]>> => {
  const email = query.meta?.email;
  if (!email) return { message: '', status: '', data: [] };

  const response = await axios(Constants.baseUrl).post(`/pwned`, { email });
  return Utils.setPwnedStatus(response.data);
};

const searchEmail = async (
  options: QueryFunctionContext<string[]>,
): Promise<BaseResponse<string[]> | null> => {
  if (!options.queryKey[1]) return null;
  const response = await axios(Constants.baseUrl).get(
    `/search-email?query=${options.queryKey[1]}`,
  );
  return response.data;
};

const pwnedList = async (): Promise<BaseResponse<PwnedListResponse[]>> => {
  const response = await axios(Constants.baseUrl).get(`/pwned-list`);
  return response.data;
};

const deleteEmail = async (
  request: DeleteEmailRequest,
): Promise<BaseResponse<null>> => {
  const response = await axios(Constants.baseUrl).delete(`/delete-breach`, {
    data: request,
  });
  return response.data;
};

const seenBreach = async (
  request: SeenRequest,
): Promise<BaseResponse<SeenResponse | null>> => {
  const response = await axios(Constants.baseUrl).post(`/breach-seen`, request);
  return response.data;
};

export const useSearchPwned = (query: string) =>
  useQuery(['search-breaches'], searchPwned, {
    enabled: false,
    meta: {
      email: query,
    },
  });

export const useSearchEmail = (enabled: boolean, query = '') =>
  useQuery(['search-email', query], searchEmail, {
    staleTime: 1000 * 60,
    cacheTime: 1000 * 60,
    useErrorBoundary: false,
    enabled,
  });

export const usePwnedList = () => useQuery(['search-email'], pwnedList);

export function useBreachSeen() {
  const queryClient = useQueryClient();
  return useMutation<any, BaseErrorResponse, SeenRequest>(async data => {
    const response = await seenBreach(data);
    if (response) {
      const queryData = queryClient.getQueryData<BaseResponse<PwnedResponse[]>>(
        ['search-breaches'],
      );
      const user = queryClient.getQueryData<BaseResponse<User>>([
        'user-profile',
      ]);

      const newData = queryData?.data?.map(it => {
        if (it.main_id.toString() === data.breach_id)
          return {
            ...it,
            id: response.data?.id,
            user_id: response.data?.id ? user?.data.id : null,
            disableScroll: true,
          };
        return it;
      });

      queryClient.setQueryData(['search-breaches'], {
        ...queryData,
        data: newData,
      });

      await queryClient.invalidateQueries(['search-breaches']);
    }
    return response;
  });
}

export function useDeleteEmail() {
  return useMutation<any, BaseErrorResponse, DeleteEmailRequest>(deleteEmail);
}
