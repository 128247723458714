import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
  useResend,
  useSignUp as useSignupMutation,
} from '../../../app/hooks/useUser';
import {
  emailValidation,
  basicFieldValidation,
  passwordValidation,
  corporateNameValidation,
  policyNumberValidation,
} from '../../../utils/validations';
import { useAuth } from '../../../app/contexts/AuthContext';

const schema = yup.object({
  first_name: basicFieldValidation,
  last_name: basicFieldValidation,
  email: emailValidation,
  password: passwordValidation,
  corporate_name: corporateNameValidation,
  policy_number: policyNumberValidation,
  // .matches(/^\d+(?:-\d+)*$/, 'Policy number is invalid'),
  app_id: yup.string().default('2XybYTuPwHu7dtWPuie4llHMgb1'),
});
type FormType = yup.InferType<typeof schema>;

export default function useSignUp() {
  const navigate = useNavigate();
  const signup = useSignupMutation();
  const resendToken = useResend();
  const auth = useAuth();

  const form = useForm<FormType>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
  });

  // React.useEffect(() => {
  //   const value = form.watch('policy_number');
  //   form.setValue('policy_number', value?.replace(/(\d{3})(\d{3})/, '$1-$2'));
  //   return () => {};
  // }, [form.watch('policy_number')]);

  React.useEffect(() => {
    const func = async () => {
      if (
        signup.error?.code === '401' ||
        signup.error?.message.includes('User is not confirmed')
      ) {
        await resendToken.mutateAsync({
          email: form.getValues('email'),
          app_id: '2XybYTuPwHu7dtWPuie4llHMgb1',
        });
        setTimeout(() => {}, 3000);
        navigate({
          pathname: '/verify',
          search: `?email=${encodeURIComponent(form.getValues('email'))}`,
        });
      }
    };
    func();
  }, [signup.isError]);

  React.useEffect(() => {
    if (signup.isError) {
      signup.reset();
    }

    return () => {};
  }, [form.watch('email')]);

  const onFormSubmit = async (data: FormType) => {
    await signup.mutateAsync(data);
    toast.success('Email has been sent with verification code');
    auth?.setVerificationFalse();
    navigate({
      pathname: '/verify',
      search: `?email=${encodeURIComponent(form.getValues('email'))}`,
    });
  };

  return {
    form,
    signup,
    onFormSubmit,
  };
}
