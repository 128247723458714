import React from 'react';

interface IInfoCardsProps {
  image: string;
  heading: string;
  description: string;
}

function InfoCards({ image, heading, description }: IInfoCardsProps) {
  return (
    <div className='flex flex-col m-auto w-full sm:w-11/12'>
      <div className='flex space-x-1 p-5 pt-1'>
        <div className='basis-1/2'>
          <img src={image} alt='img' className='w-full' />
        </div>
        <div className='basis-full flex flex-col space-y-2 pl-2'>
          <h1
            className='font-open-sans font-semibold text-md sm:text-lg text-gray-700 '
            dangerouslySetInnerHTML={{ __html: heading }}
          />

          <hr />
          <p
            className='text-sm text-gray-500'
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    </div>
  );
}

export default InfoCards;
