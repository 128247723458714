/* eslint-disable react/button-has-type */
import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

interface DropDownItem {
  name: string;
  value: string;
}

interface DropdownProps {
  items: DropDownItem[];
  onSelect: (item: DropDownItem) => void;
  value?: string;
}

export default function SortByFilter(props: DropdownProps) {
  return (
    <div className='text-right'>
      <Menu as='div' className='relative inline-block text-left'>
        <div>
          <Menu.Button className='inline-flex w-full justify-center rounded-md mx-1 md:px-4  text-xs sm:text-sm font-normal text-black hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-opacity-75'>
            Sort by
            <ChevronDownIcon
              className='sm:ml-2 -mr-1 w-4 sm:w-5 text-black  '
              aria-hidden='true'
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='z-10 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className='px-1 py-1 bg-[#E9E9EC]'>
              {props.items.map(item => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        props.onSelect(item);
                      }}
                      className={`${
                        active ? 'bg-gray-500 text-white' : 'text-gray-900'
                      } group flex w-full items-center rounded-md px-2 py-2 text-xs sm:text-sm  justify-between`}
                    >
                      <span>{item.name}</span>
                      <span>
                        {props.value === item.name && (
                          <CheckIcon className='ml-2 h-4 w-4 ' />
                        )}
                      </span>
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
