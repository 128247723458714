const Assets = {
  svg: {
    boxxLogo: require('./boxx_insurance_logo.svg').default,
    boxxLogoWhite: require('./boxx_insurance_logo_footer_white.svg').default,
    boxxLogoGray: require('./boxx_insurance_logo_gray.svg').default,
    boxxLogoSidebar: require('./boxx_logo_sidebar.svg').default,
    icChecBox: require('./ic_checkbox.svg').default,
    icError: require('./ic_error.svg').default,
    icRightArrow: require('./ic_right_arrow.svg').default,
    icBackArrow: require('./ic_back_arrow.svg').default,
    icDownload: require('./icDownload.svg').default,
    icBackArrowButton: require('./icBackArrowButton.svg').default,
    icQuestionCircle: require('./question_mark_circle.svg').default,
    icInfo: require('./ic_info.svg').default,
    icDelete: require('./ic_delete.svg').default,
    icCross: require('./ic_cross.svg').default,
    icHome: require('./sidebar/ic_home.svg').default,
    icProfileButtonGradient: require('./sidebar/ic_profilebuttongradient.svg')
      .default,
    icDarkWeb: require('./sidebar/ic_darkWeb.svg').default,
    icSecurityScore: require('./sidebar/ic_securityScore.svg').default,
    icDigitalSafetyTips: require('./sidebar/ic_DigitalSafetyTips.svg').default,
    icEmergencyRoomSideBar: require('./sidebar/ic_EmergencyRoom.svg').default,
    icSecurityTraining: require('./sidebar/ic_securityTraining.svg').default,
    icPolicyWallet: require('./sidebar/ic_policyWallet.svg').default,
    icReportBreach: require('./sidebar/ic_reportBreach.svg').default,
    icUser: require('./userProfie_Dropdown/ic_user.svg').default,
    icLogout: require('./userProfie_Dropdown/ic_Logout.svg').default,
    DarkWeb_Search: require('./darkWeb/search.svg').default,
    DarkWeb_Checks: require('./darkWeb/checks.svg').default,
    DarkWeb_Calendar: require('./darkWeb/icCalendar.svg').default,
    DarkWeb_exclamation: require('./darkWeb/exclamation.svg').default,
    icComputer: require('./computer.svg').default,
    icComingSoon: require('./icComingSoon.svg').default,
    // Security Training Assets
    icHDS: require('./securityTraining/icHDS.svg').default,
    icMOR: require('./securityTraining/icMOR.svg').default,
    icPSD: require('./securityTraining/icPSD.svg').default,
    icWFH: require('./securityTraining/icWFH.svg').default,
    icMobile: require('./securityTraining/icMobile.svg').default,
    icResultGood: require('./securityTraining/ic_result_good.svg').default,
    icResultPoor: require('./securityTraining/ic_result_bad.svg').default,
    question_1: require('./securityTraining/questionAssets/question1.png')
      .default,
    question_2: require('./securityTraining/questionAssets/question5.png')
      .default,
    icBreaches: require('./reportBreach/ic_breaches.svg').default,
    /* login Page Assets */
    icLoginHero: require('./login/icLoginHero.svg').default,
    icArrow: require('./login/icArrow.svg').default,
    icPhone: require('./emergencyRoom/icPhone.svg').default,
    icMail: require('./emergencyRoom/icMail.svg').default,
  },
  images: {
    // boxxOfficeShot: require('./boxx-office-shot.png').default,
    // icResultGood: require('./ic_result_good.png'),
    // icResultPoor: require('./ic_result_poor.png'),
    // icComingSoon: require('./ic_coming_soon.png'),
    // greenCheck: require('./check_mark.png').default,
    // redCheck: require('./check_mark_red.png').default,
  },
};

export default Assets;
