import blog1Image from '../../../../../assets/emergencyRoom/emergency_support_article_1.webp';
import blog2Image from '../../../../../assets/emergencyRoom/emergency_support_article_2.webp';
import blog3Image from '../../../../../assets/emergencyRoom/emergency_support_article_3.webp';
import blog4Image from '../../../../../assets/emergencyRoom/emergency_support_article_4.webp';
import forward1Image from '../../../../../assets/emergencyRoom/emergency_support_tile_2.webp';
import forward2Image from '../../../../../assets/emergencyRoom/emergency_support_tile_4.webp';
// import forward3Image from '../../../../../assets/emergencyRoom/article_2.png';
// import forward4Image from '../../../../../assets/emergencyRoom/article_2.png';

export default {
  article_1: {
    content: `
    <div class="w-full">
    <span class=" text-sm font-Roboto font-medium leading-5 break-words">
      33% OF OUR BROKER READERS FELL FOR THE SCAM
    </span>
    <span class=" text-sm font-Roboto font-normal leading-5 break-words">
      of the people who opened the simulated spear phishing email, 33% clicked through to the fake “attack” website.
      <br />
      <br />
      This is what spear phishing criminals do every day – impersonate a trusted brand to try and get you to go to sites that you should avoid, download viruses, or give away your passwords.
      <br />
      <br />
    </span>
    <span class=" text-xl font-gibson font-normal leading-8 break-words">
      Why is this a concern for insurance brokers?
      <br />
      <br />
    </span>
    <span class=" text-sm font-Roboto font-normal leading-5 break-words">
      We see the crippling costs that a Spear phishing attack can lead to, especially for insurance brokers that handle sensitive data and have earned the trust of high-value customers – high-value targets in the eyes of criminals.
      <br />
      <br />
      BOXX is collaborating with MonkPhish, who have built a fun Phish training/gaming platform designed for businesses like yours.
      <br />
      <br />
      Teams, be they onsite or working remotely, can train themselves by sending each other spear phishing emails like this one. They become better at spotting suspicious emails and avoiding spear phishing attacks. Most importantly, they have fun while learning!
      <br />
      <br />
      As part of our partnership with MonkPhish, BOXX brokers qualify for preferred rates with pricing starting as low as from $5.50 per month for companies with 20 employees or less.
      <br />
      <br />
    </span> 
    <a href="https://monkphish.com/landing-page-boxx-promo-follow-up/" target='_blank' class="underline hover:text-blue-500  text-sm font-Roboto font-normal leading-5 break-words">
      Click here to find out more about MonkPhish.
    </a>
    <span class="text-underline  text-sm font-Roboto font-normal leading-5 break-words">
      <br />
      <br />
    </span>
    <span class=" text-sm font-Roboto font-normal leading-5 break-words">
      With MonkPhish, all your employees are exposed to realistic simulated phishing emails in a fun and safe environment.
      <br />
      <br />
      Through an engaging, hands-on game format, they learn how to recognize and respond to phishing attacks, preparing them for real attacks when they happen.
      <br />
      <br />
      Wishing you a Phish-free rest of the year.
    </span>
  </div>
  `,
    blogHeading: 'Don’t be fooled, phishing is no joke …',
    blogDescription:
      'We recently conducted a test to highlight the threats of spear phishing. We did this to show how easy it is to be tricked by a phishing email, and why we all have to remind ourselves ever day to be extra vigilant.',
    blogImage: blog1Image,
    forwardCardHeading: 'Hackers and online scams',
    forwardCardDescription:
      'Few Canadians were spared from the national outage experienced on Friday July 8th. Adding insult to injury, savvy hackers are using this outrage to their advantage.',
    forwardCardNavigateTo: '/emergency-room/article-2',
    forwardImage: forward1Image,
  },
  article_2: {
    content: `
    <div class='w-full'>
          <span class=' text-sm font-Roboto font-normal leading-5 break-words'>
            Scam text messages claiming to offer credits have recently been
            targeting many Canadian customers, attempting to trick individuals
            while in an emotionally charged state. A company is never going to
            hide an offer or credit. Messages like these might require extra
            time to verify, but by doing so saves you endless problems in the
            long run.
            <br />
            <br />
            Don’t let yourself be fooled!
            <br />
            <br />
          </span>
          <span class=' text-xl font-open-sans font-medium leading-8 break-words'>
            Remember to always be vigilant online with our following top ten
            tips:
            <br />
            <br />
          </span>
          <span class=' text-sm font-Roboto font-medium leading-5 break-words'>
            1. Be aware of fake emails.
            <br />
          </span>
          <span class=' text-sm font-Roboto font-normal leading-5 break-words'>
            <br />
            Always navigate through your known safe bookmarks to your account if
            you suspect an unsolicited email or text might be legitimate.
            <br />
            <br />
          </span>
          <span class=' text-sm font-Roboto font-medium leading-5 break-words'>
            2. Make sure the name and the email address are consistent.
            <br />
          </span>
          <span class=' text-sm font-Roboto font-normal leading-5 break-words'>
            <br />
            Check that the email is originating from the real sender. Some
            characters can be slightly altered, going undetected upon quick
            view.
            <br />
            <br />
          </span>
          <span class=' text-sm font-Roboto font-medium leading-5 break-words'>
            3. Be aware of attachments.
            <br />
          </span>
          <span class=' text-sm font-Roboto font-normal leading-5 break-words'>
            <br />
            Scan them before opening. They can include malware.
            <br />
            <br />
          </span>
          <span class=' text-sm font-Roboto font-medium leading-5 break-words'>
            4. Be aware of criminals using trusted organizations branding.
            <br />
          </span>
          <span class=' text-sm font-Roboto font-normal leading-5 break-words'>
            <br />
            Fake emails can include brand logos and imitate well-designed
            branded web pages. Remember to check domain names.
            <br />
            <br />
          </span>
          <span class=' text-sm font-Roboto font-medium leading-5 break-words'>
            5. Be suspicious of messages that contain urgent action requests.
            <br />
          </span>
          <span class=' text-sm font-Roboto font-normal leading-5 break-words'>
            <br />
            Hackers know we are most vulnerable when we are emotionally charged
            or feeling rushed. Try to be extra cautious while in this state of
            mind.
            <br />
            <br />
            Additionally, remember when online:
            <br />
          </span>
          <ol class="text-sm font-Roboto font-normal leading-5 break-words"> 
          <li>1. Be careful about sharing your confidential information. Legitimate organizations will never ask to share sensitive information by email.</li> 
          <li>2. Keep your browser updated.</li> 
          <li>3. Be careful what you post on social media.</li> 
          <li>4. Be aware that malicious links can be posted on social media.</li> 
          <li>5. Stay updated and informed about phishing attacks.</li>
          </ol>
          <span class=' text-sm font-Roboto font-normal leading-5 break-words'>
            <br />
            Ask your broker how BOXX Insurance and our flagship product,
            Cyberboxx, can protect you, your family, and your business from a
            savvy hacker attack.
          </span>
        </div>`,
    blogHeading: 'Hackers and online scams',
    blogDescription:
      'Few Canadians were spared from the national outage experienced on Friday July 8th. Adding insult to injury, savvy hackers are using this outrage to their advantage.',
    blogImage: blog2Image,
    forwardCardHeading: 'Reducing the risk of RDP brute force attacks',
    forwardCardDescription:
      'RDP brute force attacks are commonly used by hackers targeting remote working employees, and this new control will make brute forcing much harder.',
    forwardCardNavigateTo: '/emergency-room/article-4',
    forwardImage: forward2Image,
  },
  article_3: {
    content: `<div class="w-full">  
    <p class="text-sm font-roboto font-normal  break-words">
      Luckily, they were on the ball. They contacted their broker as soon as they discovered the incident and are currently working hard to contain the damage and the reputation hit.
    <br/>
      This incident, and the many thousand mirror copies that occur each day, highlight the role and responsibility all levels of the firm play in ensuring the security of their own company’s assets.
      <br/>
      <br/>  
    </p>
    <h1 class="text-xl font-gibson font-normal break-words">
      C-Suite email credentials are for sale on the dark web from $250 per user.
    </h1>
      <br/>
    <p class="text-sm font-roboto font-normal break-words">
      Phishing is becoming increasingly commoditized. As an example, cybercriminals have created a phishing kit featuring fake Microsoft Office 365 password alerts as a lure to target the credentials of chief executives, business owners and with ‘chief financial something’ in their title.
      <br/>
      <br/>    
  
      Security specialist, Trend Micro, has identified several dark web forums selling compromised Office 365 credentials for executives at a cost of $250 to $500 per user.
      <br/>
      <br/>
      Leader Courage – “It won’t happen to me!”
      <br/>
      <br/>
      Unfortunately, this type of threat isn’t always easy to get across to senior executives. You probably still come across top executives sometimes that view email security mechanisms or policies as “an inconvenience to them”.
      <br/>
      <br/>
      The attackers know this and target high profile employees who may not be as technically – or cybersecurity – savvy, and may be more likely to be deceived into clicking on malicious links.
      <br/>
      <br/>
      If an executive is tricked into giving away their credentials – the criminals can use those details to conduct additional attacks. In our incident last week, business email compromise (BEC) was the desired outcome; targeting other employees and third-party partners in the executive’s address book with a mission to phish them as well.
      <br/>
      <br/>
    </p>
    <h3 class="text-xl font-gibson font-normal  break-words">
      What do you think is behind the gusto?
    </h3>
    <br/>
    <p class="text-sm font-roboto font-normal  break-words">
      We asked why some executives behave in this risky manner. We distilled it down to three points – I am sure you have others.
    </p>
    <br/>
    <ol class="text-sm font-roboto font-normal  break-words pl-5" style="list-style-type:decimal; ">
    <li>
      Some executives believe that they are immune to being duped, even though “they are well aware that phishing techniques have evolved,” said one.
      <br/>
      <br/>

      </li>
    <li>
      For others, they may not have yet experienced a significant breach, meaning the perception of the risks are not as real as they should be.
      <br/>
      <br/>

      </li>
    <li>
      Some executives also use a personal assistant to go through emails, which can impact the individual’s ability to spot suspicious messages.

      </li>
  </ol>
  <br/>    
  
    <h3 class="text-xl font-gibson font-normal  break-words">
      So, what to do?
    </h3>
    <br/>
    <p class="text-sm font-roboto font-normal  break-words">
      Here’s the choice for top operators at your clients’ firms:
    <br/>    
    <br/>    
    
    <ul class="text-sm font-roboto font-normal  break-words pl-5" style="list-style-type:inherit">
    <li>
    Spend an extra minute or two each day to be a bit more vigilant with what they click on, and become a role model for others in the firm.
    </li>
    </ul>
    <br/>

    OR
    <br/>
    <br/>
    <ul class="text-sm font-roboto font-normal  break-words pl-5" style="list-style-type:inherit">
    <li>
    Continue being cavalier and risk being at the end of a spear-phishing attack; resulting in reputational damage and costs.
    </li>
  </ul>
  
     <br/>    
 
      Hang on. Got to go. Just received an urgent email request to update my Office 365 password.
      <br/>    
      <br/>    
  
      Blog written by Vishal Kundi, CEO & Co-Founder of BOXX Insurance Inc.
    </p>
  </div>
  `,
    blogHeading: 'Spear-phishing: What is it? What to do?',
    blogDescription:
      'In a cyber incident reported to us last week, a senior employee in the insured’s finance department discovered their email address had been compromised; when a phishing email was broadcast from their email address to their entire contact list.',
    blogImage: blog3Image,
    forwardCardHeading: 'Hackers and online scams',
    forwardCardDescription:
      'Few Canadians were spared from the national outage experienced on Friday July 8th. Adding insult to injury, savvy hackers are using this outrage to their advantage.',
    forwardCardNavigateTo: '/emergency-room/article-2',
    forwardImage: forward1Image,
  },
  article_4: {
    content: `
    <div class="w-full">
    <span class=" text-sm font-Roboto font-normal leading-4 break-words">
      Good news for your clients with employees working from home or remotely. Microsoft is now
      <span class="text-underline"> taking steps</span>
      to prevent Remote Desktop Protocol (RDP) brute-force attacks as part of the latest builds for the Windows 11 operating system.
      <br />
      <br />
      Working from home is now the norm for many of your clients’ employees, and therefore remotely logging in to corporate VPNs and application suites are essential. According to researchers at cybersecurity company ESET, there was a reported 768% growth in Remote Desktop Protocol (RDP) attacks in 2020, and this percentage continues to increase. In total, ESET detected 29 billion attempted RDP attacks in 2020 as cyber criminals try to exploit remote workers.
      <br />
      <br />
    </span>
    <span class=" text-xl font-gibson font-normal leading-4 break-words">
      How do I describe an RDP Attack to my clients?
      <br />
      <br />
    </span>
    <span class=" text-sm font-Roboto font-normal leading-4 break-words">
      An RDP allows one computer to connect to another or a network without direct contact. A Remote Desktop Protocol Attack is a type of
      <span class="text-underline"> data breach</span>
      which occurs via a user’s
      <span class="text-underline"> remote desktop protocol</span>
      (or RDP). Brute-forcing is a method used by attackers to take over accounts. Usually automated with the help of a software tool, brute force attacks involve submitting many passwords in a row until the right one is “guessed”.
      <br />
      <br />
    </span>
    <span class=" text-xl font-gibson font-normal leading-4 break-words">
      Why is Microsoft’s action good news?
      <br />
      <br />
    </span>
    <span class=" text-sm font-Roboto font-normal leading-4 break-words">
      Previously, there was no timeout for multiple failed attempts at logging on. In new builds, accounts automatically lock for 10 minutes after 10 invalid sign-in attempts. This feature was present in previous versions but was not enabled by default. This is a step in the right direction.
      <br />
      <br />
    </span>
    <span class=" text-xl font-gibson font-normal leading-4 break-words">
      Points to emphasize with your clients:
      <br />
      <br />
    </span>
    <span class=" text-sm font-Roboto font-medium leading-4 break-words">
      1. The easiest method to prevent RDP attacks is to not expose the port to the internet.
    </span>
    <span> If external connections are required, then they should be done through a virtual private network (VPN).</span>
    <br />
    <br />
    <span class="text-sm font-Roboto font-medium leading-4 break-words">2. If an RDP port must be exposed to the internet, it should be protected with multi-factor authentication (MFA). </span>
    <span class=" text-sm font-Roboto font-normal leading-4 break-words">
      <br />
      <br />
      Additionally, an organization could use just-in-time tokens. These are temporarily-generated tokens that will provide access to a resource at a specific point in time. After they expire, they’re useless so if they’re compromised it’s only a risk until the token expires.
      <br />
      <br />
      Please let our team know if you’d like to
      <a  href='https://boxxinsurance.com/' target='_blank' class="underline hover:text-blue-500"> help your clients</a>
      boost their digital risk management, and I can connect you with our Hackbusters advisory team.
    </spa>
  </div>
  `,
    blogHeading: 'Reducing the risk of RDP brute force attacks',
    blogDescription:
      'RDP brute force attacks are commonly used by hackers targeting remote working employees, and this new control will make brute forcing much harder.',
    blogImage: blog4Image,
    forwardCardHeading: 'Hackers and online scams',
    forwardCardDescription:
      'Few Canadians were spared from the national outage experienced on Friday July 8th. Adding insult to injury, savvy hackers are using this outrage to their advantage.',
    forwardCardNavigateTo: '/emergency-room/article-2',
    forwardImage: forward1Image,
  },
};
