import React from 'react';
import Assets from '../../../assets';
import Button from '../../app/Button';

interface ErrorPageProps {
  error: Error;
  onRefreshClick?: () => void;
}

export default function ErrorPage(props: ErrorPageProps) {
  return (
    <div className='container mx-auto mt-10 flex flex-col justify-center'>
      <img src={Assets.svg.boxxLogo} alt='' className='h-20' />
      {/* <div className='block'> */}
      {/* <img src={Assets.svg.icError} alt='' className='h-10' /> */}
      <h1 className='text-red-600 text-center text-lg mt-5'>
        {props.error?.message}
      </h1>
      {/* </div> */}
      {props.onRefreshClick && (
        <div className='w-64 mx-auto my-16'>
          <Button title='refresh' onClick={props.onRefreshClick} />
        </div>
      )}
    </div>
  );
}
