import React, { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function PrivateRoute(props: PropsWithChildren) {
  const auth = useAuth();

  if (auth?.token) return <Navigate to='/dashboard' replace />;

  return <div>{props.children}</div>;
}
