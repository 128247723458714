import React from 'react';
import { NavLink } from 'react-router-dom';
import { useFetchUser } from '../../../app/hooks/useUser';
import SideBarList from './SideBarList';
import SkeletonAnim from './SkeletonAnim';
import Assets from '../../../assets';
import { useAuth } from '../../../app/contexts/AuthContext';

interface SideBarProps {
  collapsed: boolean;
  onItemClick: () => void;
  showZAsessment: boolean;
}

export default function SideBar(props: SideBarProps) {
  const user = useFetchUser();
  const auth = useAuth();

  const onLogoutClick = () => auth?.handleLogout();

  return (
    <aside
      id='logo-sidebar'
      className={`fixed overflow-y-auto border-r-primary-500 border-r-2 top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform ${
        props.collapsed ? '-translate-x-full' : 'translate-x-0'
      } bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700`}
      aria-label='Sidebar'
    >
      {user.isLoading ? (
        <SkeletonAnim />
      ) : (
        <div className='h-full flex flex-col justify-between px-3 pb-4  bg-white dark:bg-gray-800'>
          <div>
            <NavLink
              to='dashboard'
              className='absolute top-0 p-6 tall:mt-5 flex items-center justify-center w-[90%]'
            >
              <img
                src={Assets.svg.boxxLogo}
                alt='logo'
                className='w-32 tall:w-40 '
              />
            </NavLink>
            {/* <div className='flex justify-center items-center'>
              <img
                src={Assets.svg.boxxLogoSidebar}
                alt='logo'
                className='w-44 m-auto absolute top-0 p-2 ptt-4 '
              />
            </div> */}
            <SideBarList
              onItemClick={props.onItemClick}
              user={user.data?.data}
              showZAsessment={props.showZAsessment}
            />
          </div>
          <div className='p-2 pb-10'>
            <div className=''>
              <img
                src={Assets.svg.boxxLogoSidebar}
                alt='logo'
                className='w-44 m-auto'
              />
              <div className='flex flex-row justify-start py-6 '>
                <div
                  className='font-Roboto text-center align-middle text-lg text-gray-700 font-medium  min-h-[40px] min-w-[40px] max-h-[40px] max-w-[40px] border rounded-full bg-cover bg-center flex items-center justify-center'
                  style={{
                    backgroundImage: `url(${Assets.svg.icProfileButtonGradient})`,
                  }}
                >
                  <span className='min-w-full'>
                    {user.data?.data.first_name
                      ? (user.data?.data.first_name.toUpperCase().charAt(0) ||
                          '') +
                        (user.data?.data.last_name.toUpperCase().charAt(0) ||
                          '')
                      : ''}
                  </span>
                </div>

                <div className='px-3  truncate '>
                  <p className='font-open-sans text-base font-medium text-zinc-700'>
                    {`${user.data?.data.first_name} ${user.data?.data.last_name}`}
                  </p>
                  <p
                    className='font-robot text-xs font-medium text-gray-500 '
                    title={user.data?.data.email}
                  >
                    {user.data?.data.email}
                  </p>
                </div>
              </div>

              <div className='flex justify-left text-[#3F3F75] '>
                <div className=' w-full'>
                  <p>
                    <NavLink
                      to='/settings'
                      onClick={props.onItemClick}
                      className='font-Roboto font-medium text-sm '
                    >
                      {({ isActive }) => (
                        <span
                          className={
                            isActive
                              ? 'underline underline-offset-4 flex items-center py-2 pl-1 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700  transition duration-300 text-[#262647]'
                              : 'flex items-center py-2 pl-1 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700  transition duration-300'
                          }
                        >
                          Account settings
                        </span>
                      )}
                    </NavLink>
                  </p>

                  <button
                    type='button'
                    onClick={onLogoutClick}
                    className='font-Roboto appearance-none font-medium text-sm text-left py-2 pl-1 w-full hover:bg-gray-100 rounded-lg'
                  >
                    Log out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </aside>
  );
}
