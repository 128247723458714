/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Button from '../../app/Button';
import Input from '../../app/Input';

import useSignUp from './useSignUp';
import FormError from '../../app/FormError';

import FormModal from '../../app/layout/FormModal';

export default function SignUp() {
  const { form, signup, onFormSubmit } = useSignUp();

  return (
    <FormModal
      heading='Create your Membership account'
      description='Enter your details below'
    >
      <div className='space-y-6 w-full sm:w-3/4 '>
        <FormProvider {...form}>
          <FormError error={signup.error} />
          <Input type='text' name='first_name' label='First name' />
          <Input type='text' name='last_name' label='Last name' />
          <Input type='email' name='email' label='Email' />
          <Input
            type='password'
            name='password'
            label='Password'
            isPasswordField
          />
          {/* <Input type='text' name='corporate_name' label='Corporate name' /> */}
          <Input type='text' name='policy_number' label='Policy number' />
        </FormProvider>
      </div>
      <div className='w-max pt-8 space-y-3'>
        <Button
          title='Sign up'
          onClick={form.handleSubmit(onFormSubmit)}
          isLoading={signup.isLoading}
        />
        <p className='text-gray-700 text-xs sm:text-sm'>
          Already a member?{' '}
          <Link to='/signIn' className=' self-start  mt-2'>
            <span className='underline'>Back to Login Page</span>
          </Link>
        </p>
      </div>
    </FormModal>
  );
}
