import Question from '../Question';
import Option from '../Option';

const ProtectingQuestions: Question[] = [
  new Question(
    '1',
    'Which is the MOST serious impact of having your personal information stolen from your computer or mobile device?',
    [
      new Option('1', 'The inconvenience of checking my emails more often'),
      new Option(
        '2',
        'Criminals using my information to hack into sensitive accounts',
      ),
      new Option(
        '3',
        'The inconvenience of restoring personal information to my device from a backup',
      ),
      new Option(
        '4',
        'Notifying the police that my personal information has been stolen',
      ),
    ],
    '2',
  ),

  new Question(
    '2',
    'Which is an example of sensitive personal information?',
    [
      new Option('1', 'My date of birth'),
      new Option('2', 'My name'),
      new Option('3', 'My work address'),
      new Option('4', 'My job title'),
    ],
    '1',
  ),

  new Question(
    '3',
    'How should you protect personal information that you share on social media sites?',
    [
      new Option(
        '1',
        'I should delete personal information as soon as my friends see it',
      ),
      new Option(
        '2',
        'I should never share any personal information on social media sites',
      ),
      new Option(
        '3',
        'I should use privacy settings to limit what I share, and who I share it with',
      ),
      new Option(
        '4',
        "I don't need to take any action, because the site protects this information for me",
      ),
    ],
    '2',
  ),

  new Question(
    '4',
    'How should you dispose of paper documents containing sensitive information?',
    [
      new Option('1', 'Use the waste disposal unit for the local building'),
      new Option('2', 'Use a shredder to destroy the documents'),
      new Option(
        '3',
        'Rip up the documents and put them in the office waste bin',
      ),
      new Option('4', 'Burn the documents in a safe location'),
    ],
    '2',
  ),

  new Question(
    '5',
    'What should you do FIRST if you suspect that a personal device containing sensitive information has been lost?',
    [
      new Option('1', 'Identify what information has been lost'),
      new Option('2', 'Contact the person whose information it is'),
      new Option('3', 'Order a replacement device'),
      new Option('4', 'File a police report and notify of identity theft'),
    ],
    '4',
  ),

  new Question(
    '6',
    'How should you mail a portable hard drive containing sensitive data to another location?',
    [
      new Option(
        '1',
        "Use a secure courier service so that the portable hard drive can't be stolen",
      ),
      new Option(
        '2',
        'Protect the portable hard drive with a strong password and provide the password separately',
      ),
      new Option(
        '3',
        'Wrap the portable hard drive in bubble wrap to protect it from damage',
      ),
      new Option(
        '4',
        'Send the portable hard drive in the post, the same way that you would send a letter',
      ),
    ],
    '2',
  ),

  new Question(
    '7',
    'You receive a phone call from a business asking for personal information.  What should you do?',
    [
      new Option(
        '1',
        'Provide the information that they need as they have used the correct phone number',
      ),
      new Option(
        '2',
        'Ask them to confirm their name before providing any information',
      ),
      new Option(
        '3',
        'Take the caller through an identity verification process',
      ),
      new Option('4', 'Take their number and call them back'),
    ],
    '3',
  ),

  new Question(
    '8',
    'How should you safely share a password and sensitive data to a verified individual online?',
    [
      new Option(
        '1',
        'I should include the password in the email with the confidential data',
      ),
      new Option(
        '2',
        'I should send the password in a separate email to the confidential data',
      ),
      new Option('3', 'I should text the password to the email recipient'),
      new Option(
        '4',
        'The email with the data should tell the verified individual to phone me and ask for the password',
      ),
    ],
    '4',
  ),
  new Question(
    '9',
    'What should you NOT do when using public Wi-Fi?',
    [
      new Option('1', "Don't access your personal or financial information"),
      new Option(
        '2',
        'Stay permanently signed in to accounts to avoid inputting a password more than once',
      ),
      new Option('3', 'Pay attention to warnings'),
      new Option('4', 'Stick to “HTTPS” websites'),
    ],
    '2',
  ),
  new Question(
    '10',
    'What is the definition of personal data?',
    [
      new Option(
        '1',
        'Any data that can directly or indirectly identify a living person',
      ),
      new Option('2', 'Any data that a person has on their phone'),
      new Option('3', 'Any data that a person shares on social media'),
      new Option('4', 'Any data that a person wants to keep private'),
    ],
    '1',
  ),
  new Question(
    '11',
    'Under the GDPR, what must an organization have to be able to process your personal data?',
    [
      new Option('1', 'A business reason to process my data'),
      new Option('2', 'A public source from which they obtained my data'),
      new Option('3', 'A lawful basis for processing my data'),
      new Option('4', 'Permission from the police to process my data'),
    ],
    '3',
  ),
  new Question(
    '12',
    "Which is 'special category' personal data?",
    [
      new Option('1', "A person's full name"),
      new Option('2', "A person's email address"),
      new Option('3', "A person's credit card details"),
      new Option('4', "A person's ethnic origin"),
    ],
    '4',
  ),
  new Question(
    '13',
    'An organization processing your data must ensure that your data...',
    [
      new Option('1', 'is always saved in the cloud and encrypted.'),
      new Option('2', 'is always processed lawfully.'),
      new Option('3', 'is shared with other companies.'),
      new Option('4', 'is anonymized.'),
    ],
    '2',
  ),
  new Question(
    '14',
    'When using a USB stick to carry sensitive data, what should you do?',
    [
      new Option(
        '1',
        "Keep the data on the USB stick until I'm sure I won't need it again",
      ),
      new Option(
        '2',
        'Use a single USB stick for both sensitive and non-sensitive data',
      ),
      new Option(
        '3',
        'Duplicate the sensitive data onto other USB sticks incase you misplace one',
      ),
      new Option('4', 'Ensure that the data on the USB stick is encrypted'),
    ],
    '4',
  ),
  new Question(
    '15',
    'Which statement is INCORRECT?',
    [
      new Option(
        '1',
        'Removable media can be useful for storing large quantities of information',
      ),
      new Option(
        '2',
        'Using removable media inappropriately can result in a serious security incident',
      ),
      new Option(
        '3',
        'Removable media is secure for storing and transferring information',
      ),
      new Option(
        '4',
        'Removable media is a cost effective way to store information',
      ),
    ],
    '2',
  ),
];

export default ProtectingQuestions;
