import Question from '../Question';
import Option from '../Option';

const WorkFromHomeQuestions: Question[] = [
  new Question(
    '1',
    'Why is it important to understand the threats to your device, and their possible impact?',
    [
      new Option('1', 'To know when I should stop using my device'),
      new Option('2', 'To know how much insurance I should have for my device'),
      new Option('3', 'To make decisions about how to protect my device'),
      new Option('4', 'To make decisions about when to replace my device'),
    ],
    '3',
  ),

  new Question(
    '2',
    'Downloading applications, software, music etc. from a reputable source...',
    [
      new Option(
        '1',
        'reduces the risk of an attacker stealing my information.',
      ),
      new Option('2', 'is a waste of money.'),
      new Option(
        '3',
        'can attract the attention of an attacker who wants to intercept my download.',
      ),
      new Option('4', 'offers no additional security to my device or data.'),
    ],
    '1',
  ),

  new Question(
    '3',
    'You want to understand how to use a device securely and ethically. Where should you look FIRST?',
    [
      new Option('1', "My company's records management policy"),
      new Option('2', 'My employment contract with my company'),
      new Option('3', "My company's acceptable use policy"),
      new Option('4', "My company's identity and access management policy"),
    ],
    '3',
  ),

  new Question(
    '4',
    'Which is the strongest password?',
    [
      new Option('1', 'B@n@na$'),
      new Option('2', 'CB@n@n@$'),
      new Option('3', 'Bananas'),
      new Option('4', '@Bananas'),
    ],
    '2',
  ),

  new Question(
    '5',
    'Why should you use different passwords for each online account?',
    [
      new Option('1', 'To make it hard to remember the passwords'),
      new Option(
        '2',
        'Because different accounts have different password lengths',
      ),
      new Option('3', 'To make the passwords harder to guess'),
      new Option(
        '4',
        'Because if one password is stolen, a hacker cannot use it to access other accounts',
      ),
    ],
    '4',
  ),

  new Question(
    '6',
    'What is a virtual private network (VPN) used for?',
    [
      new Option('1', 'Sending emails and browsing the web'),
      new Option('2', 'Quickly downloading patches to my mobile device'),
      new Option('3', 'Creating a secure link with my company network'),
      new Option('4', 'Allowing my company to monitor my activity'),
    ],
    '3',
  ),

  new Question(
    '7',
    'You need to store sensitive company information on your encrypted laptop.  What should you do to protect this data?',
    [
      new Option(
        '1',
        "I don't need to do anything, because encryption will protect the sensitive data",
      ),
      new Option(
        '2',
        "I should send a copy of the data to my work email whenever I make a change, so it can't be lost",
      ),
      new Option(
        '3',
        'I should delete the data from the company network, so there are no conflicting versions',
      ),
      new Option(
        '4',
        'I should delete all local copies of the sensitive data as soon as I have finished using it',
      ),
    ],
    '4',
  ),

  new Question(
    '8',
    'What should you do when using your mobile device to work remotely in a public place?',
    [
      new Option(
        '1',
        "Keep my device in clear view even when I'm not using it ",
      ),
      new Option(
        '2',
        'Connect my device to the fastest available Wi-Fi network',
      ),
      new Option(
        '3',
        'Ensure that confidential information cannot be viewed by those around me',
      ),
      new Option(
        '4',
        'Use a single password for all the systems and software on my device',
      ),
    ],
    '3',
  ),
  new Question(
    '9',
    'How can you make sure that all data moving to or from your mobile device is private and secure?',
    [
      new Option('1', 'I should encrypt all data on my device'),
      new Option(
        '2',
        'I should install patches on my device as soon as they are available',
      ),
      new Option('3', 'I should use a VPN whenever I connect to the internet'),
      new Option(
        '4',
        'I should only use company apps on my device, not personal ones',
      ),
    ],
    '4',
  ),
  new Question(
    '10',
    'You are out of the office and need to send a large amount of sensitive information to a client quickly. What is the BEST action to take?',
    [
      new Option(
        '1',
        'I should find the nearest public Wi-Fi connection and send the information',
      ),
      new Option(
        '2',
        'I should connect to any WiFi connection, but check that no one can see my screen when sending the information',
      ),
      new Option(
        '3',
        'I should use a strong WiFi connection, but divide the information into small amounts before sending it',
      ),
      new Option(
        '4',
        'I should connect to a trusted Wi-Fi network and send the information using my company VPN',
      ),
    ],
    '2',
  ),
  new Question(
    '11',
    'If you receive an e-mail from a contact and you suspect their account has been compromised, what would be the best course of action to take?',
    [
      new Option('1', 'Ignore them'),
      new Option('2', 'Forward on the e-mail to someone who works in IT'),
      new Option(
        '3',
        'Contact them as soon as possible via phone or in person to share your suspicions',
      ),
      new Option('4', 'Reply to the e-mail to verify the request'),
    ],
    '4',
  ),
  new Question(
    '12',
    'Why might it raise suspicions if you receive an urgent e-mail from an executive in your organization asking for sensitive information, and that you keep their request confidential?',
    [
      new Option('1', 'If you don’t typically receive requests of this nature'),
      new Option(
        '2',
        'Because you are being pressured by the urgency of the e-mail',
      ),
      new Option(
        '3',
        'Because you are being asked to keep the request confidential',
      ),
      new Option('4', 'All of the above'),
    ],
    '2',
  ),
  new Question(
    '13',
    'How does multi-factor authentication help protect your e-mail accounts?',
    [
      new Option('1', 'It doesn’t'),
      new Option('2', 'It helps to prevent phishing e-mails being received'),
      new Option(
        '3',
        "When attempting to log into your e-mail account, as well as your password, access to second 'factor' is required, for example a unique code sent via SMS to your mobile phone",
      ),
      new Option('4', 'It protects your computer from viruses'),
    ],
    '3',
  ),
  new Question(
    '14',
    'If you receive an e-mail from a known contact of yours which uses language that seems odd and where they are asking for sensitive information, why might this pose a risk to you and your organization?',
    [
      new Option(
        '1',
        'It may be a fraudster who has accessed the account, and is trying to obtain the information for malicious purposes',
      ),
      new Option(
        '2',
        'Because all e-mails that ask for sensitive information pose a significant risk',
      ),
      new Option(
        '3',
        'Because it will help to stop you receiving phishing e-mails',
      ),
      new Option('4', 'It will help protect your computer against viruses'),
    ],
    '3',
  ),
  new Question(
    '15',
    'Why is it important to use a strong password or passphrase to protect your e-mail accounts?',
    [
      new Option(
        '1',
        "It isn't that important as long as you check your e-mails regularly",
      ),
      new Option(
        '2',
        'To make it more difficult for anyone else to gain unauthorized access',
      ),
      new Option(
        '3',
        'Because it will help to stop you receiving phishing e-mails',
      ),
      new Option('4', 'It will help protect your computer against viruses'),
    ],
    '3',
  ),
];

export default WorkFromHomeQuestions;
