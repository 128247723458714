import Option from './Option';

/* eslint-disable no-useless-constructor */
export default class Question {
  constructor(
    readonly id: string,
    readonly question: string,
    readonly options: Option[],
    readonly answer: string,
  ) {}
}
