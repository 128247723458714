/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Controller } from 'react-hook-form';
import Redirecting from '../../../app/Redirecting';
import BreachItem from './BreachItem';
import SkeletonAnim from './SkeletonAnim';
import useDarkWeb from './useDarkWeb';
// import NoBreach from './NoBreach';
import Summery from './Summery';
// import Utils from '../../../../utils/Utils';
import SortByFilter from '../../../app/SortbyFilter';
import SearchCard from './components/SearchCard';
import PageLayout from '../../../app/layout/PageLayout';

export default function DarkWeb() {
  const hook = useDarkWeb();

  if (hook.user.isLoading) {
    return <Redirecting />;
  }

  // if (!Utils.canView(hook.user?.data?.data, 'darkweb')) {
  //   return (
  //     <h3 className='text-md md:text-lg text-center mt-4'>
  //       You are not authorized to view this page
  //     </h3>
  //   );
  // }

  return (
    <PageLayout
      leftPanel={<SearchCard hook={hook} />}
      filterBarContent={<FilterBar hook={hook} />}
      rightPanelContent={<RightPanelContent hook={hook} />}
      RightPanelFooter={<div />}
    />
  );
}

function FilterBar({ hook }: any) {
  return (
    <>
      <div className='flex items-center  text-xs sm:text-sm'>
        <p>
          {' '}
          {hook.showEmailBreaches && hook.breach.data?.data?.length !== 0
            ? 'Associated breaches'
            : 'My breaches'}
        </p>
      </div>

      <div className='flex flex-row items-center justify-between '>
        {hook.showEmailBreaches && hook.breach.data?.data?.length !== 0 ? (
          <>
            <Controller
              control={hook.form.control}
              name='sortBy'
              render={({ field }) => (
                <SortByFilter
                  items={[
                    { name: 'Date - New to old', value: 'Date - New to old' },
                    { name: 'Date - Old to new', value: 'Date - Old to new' },
                  ]}
                  onSelect={value => field.onChange(value.name)}
                  value={hook.form.watch('sortBy')}
                />
              )}
            />
            <Controller
              control={hook.form.control}
              name='readFilter'
              render={({ field }) => (
                <SortByFilter
                  items={[
                    { name: 'All', value: 'All Breaches' },
                    { name: 'Read', value: 'Read Breaches' },
                    { name: 'Unread', value: 'Unread Breaches' },
                  ]}
                  onSelect={value => field.onChange(value.name)}
                  value={hook.form.watch('readFilter')}
                />
              )}
            />
            <Controller
              control={hook.form.control}
              name='filter'
              render={({ field }) => (
                <SortByFilter
                  items={[
                    { name: 'All', value: 'All Breaches' },
                    { name: 'New', value: 'New Breaches' },
                    { name: 'Removed', value: 'Removed Breaches' },
                  ]}
                  onSelect={value => field.onChange(value.name)}
                  value={hook.form.watch('filter')}
                />
              )}
            />
          </>
        ) : (
          <Controller
            control={hook.form.control}
            name='sortBy'
            render={({ field }) => (
              <SortByFilter
                items={[
                  { name: 'Date - New to old', value: 'Date - New to old' },
                  { name: 'Date - Old to new', value: 'Date - Old to new' },
                ]}
                onSelect={value => field.onChange(value.name)}
                value={hook.form.watch('sortBy')}
              />
            )}
          />
        )}
      </div>
    </>
  );
}

function RightPanelContent({ hook }: any) {
  return (
    <>
      {hook.breach.isLoading ? (
        <SkeletonAnim />
      ) : (
        <>
          {hook.showEmailBreaches && (
            <>
              {hook.breach.data?.data?.length === 0 ? (
                <Summery
                  summery={hook.summery}
                  form={hook.form}
                  onButtonClick={name => {
                    hook.form.setValue('search', name);
                    hook.form.handleSubmit(hook.onSearch)();
                  }}
                />
              ) : (
                <>
                  {hook.filteredBreaches.length === 0 ? (
                    <div className='flex flex-col mx-auto items-center mt-10'>
                      <h1 className='text-black text-xl font-bold mt-3'>
                        No results
                      </h1>
                      <h1>Try changing your filter.</h1>
                    </div>
                  ) : (
                    <ul className='flex flex-col space-y-5 '>
                      {hook.filteredBreaches.map((item: any) => (
                        <BreachItem
                          key={item.AddedDate + item.Description}
                          item={item}
                          onProtectClick={() => hook.setModalVisible(true)}
                          onSeenClick={() => hook.onItemSeenClick(item)}
                        />
                      ))}
                    </ul>
                  )}
                </>
              )}
            </>
          )}

          {!hook.showEmailBreaches && (
            <Summery
              summery={hook.summery}
              form={hook.form}
              onButtonClick={name => {
                hook.form.setValue('search', name);
                hook.form.handleSubmit(hook.onSearch)();
              }}
            />
          )}
        </>
      )}
    </>
  );
}
