import React from 'react';
// import UserDropDown from './UserDropDown';

interface NavBarProps {
  onHamburgerClick: () => void;
}

export default function NavBar(props: NavBarProps) {
  return (
    <nav className='fixed top-0 z-20 w-full sm:hidden dark:bg-gray-800 dark:border-gray-700'>
      <div className='px-3 py-3 lg:px-5 lg:pl-3'>
        <div className='flex items-center justify-between md:justify-end'>
          <button
            data-drawer-target='logo-sidebar'
            data-drawer-toggle='logo-sidebar'
            aria-controls='logo-sidebar'
            type='button'
            className='z-20 inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-200 bg-gray-100 bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600'
            onClick={props.onHamburgerClick}
          >
            <span className='sr-only'>Open sidebar</span>
            <svg
              className='w-6 h-6'
              aria-hidden='true'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                clipRule='evenodd'
                fillRule='evenodd'
                d='M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z'
              />
            </svg>
          </button>
          {/* <div className='justify-self-end'>
            <UserDropDown />
          </div> */}
        </div>
      </div>
    </nav>
  );
}
