import MOR from '../../../../assets/securityTraining/training_tile_1.webp';
import PSD from '../../../../assets/securityTraining/training_tile_4.webp';
import WFH from '../../../../assets/securityTraining/training_tile_3.webp';
import HDS from '../../../../assets/securityTraining/training_tile_2.webp';

export default [
  {
    image: MOR,
    title: 'Managing Online Risk',
    onClickNavigateTo: '/introduction/managing-risk',
    description:
      'All your policy info in one, easy to access place. Review, renew, and change policies here.',
  },
  {
    image: PSD,
    title: 'Protecting Sensitive Data',
    onClickNavigateTo: '/introduction/sensitive-data',
    description:
      'Learn how to protect sensitive information online such as your bank account, phone numbers, and more.',
  },
  {
    image: WFH,
    title: 'Working From Home',
    onClickNavigateTo: '/introduction/wfh',
    description:
      'Working at home puts you at risk for a multitude of cyber attacks. learn how to protect yourself.',
  },
  {
    image: HDS,
    title: 'Household Digital Safety',
    onClickNavigateTo: '/introduction/household',
    description:
      'Protect yourself and your family from cyber attacks and breaches online.',
  },
];
