import React from 'react';

interface PageLayoutProps {
  leftPanel: React.ReactNode;
  rightPanelContent: React.ReactNode;
  filterBarContent?: React.ReactNode;
  RightPanelFooter?: React.ReactNode;
  RightPanelSearchBar?: React.ReactNode;
}
function PageLayout({
  leftPanel,
  filterBarContent,
  rightPanelContent,
  RightPanelFooter,
  RightPanelSearchBar,
}: PageLayoutProps) {
  return (
    <div className='flex flex-col h-full xl:flex-row min-h-[600px] '>
      <div className='flex flex-col  xl:w-[50%] p-2 sm:px-8 xl:pt-6 xl:pr-32 space-y-3'>
        {leftPanel}
      </div>

      {/* right panel */}
      <div className='flex flex-col sm:p-2 sm:px-8 xl:p-0 xl:pr-4 pt-4  xl:w-[50%] xl:pt-20  space-y-2  rounded'>
        {/* filer bar */}
        {filterBarContent && (
          <div className='text-sm w-full border  flex flex-row justify-between  p-3 rounded-lg bg-[#E9E9EC] text-zinc-600 z-10'>
            {filterBarContent}
          </div>
        )}
        {RightPanelSearchBar && <div>{RightPanelSearchBar}</div>}
        {/* right panel content */}
        <div className='xl:h-full h-max xl:overflow-hidden xl:overflow-y-auto xl:pb-10 pb-4 px-1 '>
          {rightPanelContent}
        </div>
        {RightPanelFooter && (
          <div className='flex justify-center items-center py-2 border-t-4'>
            {RightPanelFooter}
          </div>
        )}
      </div>
    </div>
  );
}

export default PageLayout;
