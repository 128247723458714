import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Success() {
  const navigate = useNavigate();

  return (
    <div className='min-h-screen bg-gray-100'>
      <div className='min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 text-center'>
        <svg
          className='h-20 w-20 text-primary-900'
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'
          aria-hidden='true'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z'
          />
        </svg>

        <h2 className='text-3xl text-themeblack font-bold mt-3 mb-2'>
          Success!
        </h2>
        <p>You have unsubscribed from the weekly email report.</p>
        <p className='mt-4'>
          You can subscribe to the Weekly Report email by editing your user
          profile settings.
        </p>

        <a
          href=''
          className='mt-3 text-primary'
          onClick={() => {
            navigate('/dashboard', {
              replace: true,
            });
          }}
        >
          Back to App
        </a>
      </div>
    </div>
  );
}
