import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ContactCard from './components/ContactCard';
import BackNavigationButton from '../../app/BackNavigationButton';
import DataTile from '../../app/DataTile';

interface IBlogProps {
  content: string;
  blogHeading: string;
  blogDescription: string;
  blogImage: string;
  forwardCardHeading: string;
  forwardCardDescription: string;
  forwardCardNavigateTo: string;
  forwardImage: string;
}

function Blog(props: IBlogProps) {
  const navigate = useNavigate();
  const scrollableElementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollableElementRef.current) {
      scrollableElementRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    return () => {};
  }, [props]);

  return (
    <div
      ref={scrollableElementRef}
      className='scrollable-element min-h-full flex flex-col xl:flex-row w-full overflow-auto'
    >
      <div className='flex flex-col  xl:w-[70%] p-2 sm:px-8 xl:pt-6 xl:pr-20 space-y-4 '>
        <BackNavigationButton
          title='emergency room'
          onButtonClick={() => {
            navigate('/emergency-room');
          }}
        />
        <h1 className='page-heading'>{props.blogHeading}</h1>
        <p className='page-description'>{props.blogDescription}</p>
        <img src={props.blogImage} alt='' />

        <div dangerouslySetInnerHTML={{ __html: props.content }} />

        <div className='flex flex-col space-y-4 py-4'>
          <h1 className='font-open-sans font-semibold tracking-normal text-xl text-[#2D2D39]'>
            You should also check out:
          </h1>

          <DataTile
            heading={props.forwardCardHeading}
            description={props.forwardCardDescription}
            image={props.forwardImage}
            navigateTo={props.forwardCardNavigateTo}
          />
        </div>
      </div>
      <div className='flex justify-center xl:w-[30%] px-8 py-4 xl:py-0 xl:px-0 xl:pr-4 xl:pt-20 '>
        <div className='min-w-max sm:w-[80%]'>
          <ContactCard
            email='hackbusters@boxxinsurance.com'
            phone='1-888-349-6660'
            header='Need to report an incident?'
          />{' '}
        </div>
      </div>
    </div>
  );
}

export default Blog;
