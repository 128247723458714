/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';
import React from 'react';
import { BaseErrorResponse } from '../../data/response/BaseErrorResponse';

interface FormErrorProps {
  error: Error | null;
}

export default function FormError(props: FormErrorProps) {
  let message = '';
  if (props.error instanceof AxiosError)
    message = (props.error as BaseErrorResponse)?.message ?? '';
  else message = props.error?.message ?? '';

  if (props.error)
    return (
      <div className='flex justify-start w-full items-center mt-2'>
        <div className='text-sm rounded-md  flex justify-items-left bg-red-100 text-red-500 w-full'>
          <p className='p-2'>{message}</p>
        </div>
      </div>
    );
  return <div />;
}
