import React, { useCallback } from 'react';
import { useDeleteEmail } from '../../../../app/hooks/usePwned';
import PwnedListResponse from '../../../../data/response/PwnedListResponse';
import SummerySkeleton from '../../../app/SummerySkeleton';
import SummeryItemCard from '../../../app/SummeryItemCard';
import { sortByStatus } from '../../../../data/Enum/PwnedStatus';
import Utils from '../../../../utils/Utils';

interface SummeryProps {
  onButtonClick: (name: string) => void;
  form: any;
  summery: any;
}

export default function Summery(props: SummeryProps) {
  const [deletingItems, setDeletingItems] = React.useState<string[]>([]);
  console.log(deletingItems);
  const onDeleteClick = async (item: PwnedListResponse) => {
    setDeletingItems(prev => {
      return [...prev, item.email];
    });
    await deleteQuery.mutateAsync({ email: item.email });
    props.summery.refetch();
  };

  function sortByDate(
    data: PwnedListResponse[],
    sortBy: sortByStatus,
  ): PwnedListResponse[] {
    return [
      ...data
        .filter(
          it =>
            !(Number(it.last_week_results) === 0 && Number(it.this_week) === 0),
        )
        .sort((a, b) => {
          const dateA = new Date(a.last_breach_date || '').getTime();
          const dateB = new Date(b.last_breach_date || '').getTime();

          if (sortBy === 'Date - New to old') return dateB - dateA;

          return dateA - dateB;
        }),
      ...data.filter(
        it => Number(it.last_week_results) === 0 && Number(it.this_week) === 0,
      ),
    ];
  }

  const deleteQuery = useDeleteEmail();

  // const [_isLoading, setIsLoading] = React.useState(false);

  const debouncedOnDeleteClick = useCallback(
    Utils.debounce(onDeleteClick, 500),
    [],
  );

  // const filteredPwnedList = React.useMemo(() => {
  const filteredPwnedList = sortByDate(
    props.summery.data?.data ?? [],
    props.form.getValues('sortBy') as sortByStatus,
  );
  // }, [props.summery.data?.data, props.form.watch('sortBy')]);

  // React.useEffect(() => {
  //   props.summery.refetch();
  // }, []);

  // React.useEffect(() => {
  //   if (deleteQuery.isLoading) setIsLoading(true);
  //   // else setTimeout(() => setIsLoading(false), 700);
  // }, [deleteQuery.isLoading, deleteQuery.variables?.email]);

  // React.useEffect(() => {
  //   if (deleteQuery.isSuccess) setIsLoading(false);

  //   return () => {};
  // }, [deleteQuery.isSuccess]);

  if (props.summery.isLoading) {
    return (
      <div className='space-y-3 mt-5'>
        <SummerySkeleton />
        <SummerySkeleton />
        <SummerySkeleton />
        <SummerySkeleton />
        <SummerySkeleton />
      </div>
    );
  }

  if (filteredPwnedList.length) {
    return (
      <div className='overflow-hidden pb-4 rounded'>
        {filteredPwnedList.map(item => (
          <SummeryItemCard
            key={`${item.email}`}
            identity={item.email}
            leftHeading='New breaches'
            rightHeading='Unresolved breaches'
            last_week_results={item.last_week_results ?? '-'}
            this_week={item.this_week ?? '-'}
            lastBreachDate={item.last_breach_date}
            buttonTitle='View Breaches'
            isDeleteLoading={deletingItems.includes(item.email)}
            onButtonClick={() => props.onButtonClick(item.email)}
            onDeleteClick={() => debouncedOnDeleteClick(item)}
          />
        ))}
      </div>
    );
  }

  return (
    <div className='flex justify-center items-center h-full'>
      <h3 className='text-center text-gray-500'>
        You have no searches in your history
      </h3>
    </div>
  );
}
