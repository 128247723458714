import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import Assets from '../../../assets';
import Button from '../../app/Button';
import FormError from '../../app/FormError';
import Success from './Success';
import useSubscriptions from './useEmailSubscription';

function EmailSubscription() {
  const hook = useSubscriptions();
  const location = useLocation();

  if (!location?.search.includes('email=')) {
    return <Navigate to='/dashboard' />;
  }

  if (hook.subscribe.isSuccess) {
    return <Success />;
  }

  return (
    <div className='min-h-screen bg-gray-100'>
      <div className='min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='flex justify-evenly'>
            <a className='flex items-center'>
              <img src={Assets.svg.boxxLogo} alt='logo' className='h-12' />
            </a>
          </div>
          <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
            Unsubscribe to Weekly Email
          </h2>
        </div>

        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='grid bg-white py-8 px-4 shadow sm:rounded-lg justify-items-center'>
            <FormError error={hook.subscribe.error} />
            <form>
              <div className='mt-6 mb-4'>
                <div className='mt-'>
                  <input
                    type='email'
                    readOnly
                    value={hook.email || ''}
                    name='email'
                    id='email'
                    className='appearance-none block w-64 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  />
                </div>
              </div>

              <Button
                title='Unsubscribe'
                onClick={hook.handleSubmit}
                isLoading={hook.subscribe.isLoading}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailSubscription;
