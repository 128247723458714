import React from 'react';

interface ProgressBarProps {
  index: number;
  total: number;
}

export default function ProgressBar(props: ProgressBarProps) {
  const percent = React.useMemo(() => {
    return Math.round(((props.index + 1) * 100) / props.total);
  }, [props]);

  return (
    <div className='py-3'>
      <div className='w-full bg-gray-300 rounded-full h-2 dark:bg-gray-700'>
        <div
          className='bg-primary h-1.5 rounded-full'
          style={{ width: `${percent}%` }}
        />
      </div>
      {/* <div className='mb-1 text-base tracking-widest mt-1 font-medium text-black dark:text-white'>
        <p className='text-sm'>
          Section {props.index + 1}/{props.total} Completed
        </p>{' '}
      </div> */}
    </div>
  );
}
