import React from 'react';

export default function SkeletonAnim() {
  return (
    <div className='fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform rounded animate-pulse'>
      <Item />
      <Item />
      <Item />
      <Item />
      <Item />
      <Item />
      <Item />
    </div>
  );
}

function Item() {
  return (
    <div className='items-center gap-3 mt-2 px-4 py-2 mb-2 flex flex-row'>
      <div className='w-6 h-6 bg-gray-300 rounded-md dark:bg-gray-600' />
      <div className='flex-1 h-6 w-full float-right bg-gray-200 rounded-md dark:bg-gray-700' />
    </div>
  );
}
