import React from 'react';
import Assets from '../../../assets';

interface IFormModal {
  children: React.ReactNode;
  heading: string;
  description: string;
}
function FormModal(props: IFormModal) {
  return (
    <div className='text-gray-700 h-screen bg-gray-50 flex justify-center items-center sm:px-6 lg:px-8 overflow-hidden overflow-y-auto'>
      <div className=' m-auto sm:mx-auto w-full sm:max-w-2xl  bg-white  pt-2 px-5 sm:px-8 pb-16  shadow sm:rounded-lg justify-items-start '>
        <div className='flex justify-end w-full'>
          <img className='h-10' src={Assets.svg.boxxLogoGray} alt='boxx Logo' />
        </div>
        <div className='space-y-6 sm:tall:space-y-10 sm:space-y-6 flex flex-col '>
          <h1 className='page-heading-2 font-semibold '>{props.heading}</h1>
          <p className='page-heading-4 font-semibold '>{props.description}</p>
        </div>

        {props.children}
      </div>
    </div>
  );
}

export default FormModal;
