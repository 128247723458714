import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Button from '../../app/Button';
// import FormError from '../../app/FormError';
import Input from '../../app/Input';
import useSignIn from './useSignIn';
import Assets from '../../../assets';
import InfoCards from './components/InfoCards';
import backgroundImage from '../../../assets/login/consumer_login_hero.webp';
import tile1 from '../../../assets/login/consumer_login_tile_1.webp';
import tile2 from '../../../assets/login/consumer_login_tile_2.webp';
import tile3 from '../../../assets/login/consumer_login_tile_3.webp';
import tile4 from '../../../assets/login/consumer_login_tile_4.webp';
import tile5 from '../../../assets/login/consumer_login_tile_5.webp';
import tile6 from '../../../assets/login/consumer_login_tile_6.webp';

export default function SignIn() {
  const { form, onFormSubmit: onSubmit, error, isLoading } = useSignIn();

  return (
    <div className='bg-black flex flex-col lg:flex-row justify-center items-center min-h-screen '>
      <div className='w-full lg:w-3/5 xl:w-[60%]  lg:h-screen min-h-max overflow-visible sm:overflow-y-auto '>
        {/* <div className='bg-white p-6 rounded-lg shadow-lg'> */}
        {/* Left Div */}
        <div
          className='hero-image bg-cover bg-no-repeat bg-top min-h-[40vh] sm:h-[60vh]  lg:h-[60%] xl:h-[70%] tall:xl:h-[60%]  flex items-end'
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <h1 className='font-open-sans font-normal text-base sm:text-2xl tall:md:text-3xl lg:text-2xl tall:xl:text-4xl  tall:2xl:text-5xl  sm:px-20   p-5  text-start text-white'>
            Welcome to Cyberboxx.
            <br className='hidden sm:block' />
            Let’s get you started on a digitally safe day.
          </h1>
        </div>
        <div className='flex-grow flex flex-col justify-between  items-center sm:items-start h-[60vh] sm:h-[50%] lg:h-[40%]'>
          <div className='flex-col sm:w-2/3 md:w-1/2 w-5/6 m-auto  justify-start space-y-6 sm:space-y-3 tall:space-y-6 sm:ml-20 py-2 tall:py-0 '>
            <h1 className='font-open-sans font-semibold text-base sm:text-xl text-white '>
              Enter your details below
            </h1>
            <form
              className='space-y-2'
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <FormProvider {...form}>
                {/* <FormError error={error} /> */}
                <div className=''>
                  <Input
                    key='email'
                    type='email'
                    name='email'
                    label='Email'
                    labelTextColor='text-white'
                    formSubmitError={error?.code !== '401' && !!error}
                  />
                </div>
                <div className=''>
                  <Input
                    key='password'
                    type='password'
                    name='password'
                    label='Password'
                    isPasswordField
                    labelTextColor='text-white'
                    formSubmitError={error?.code !== '401' && !!error}
                  />
                  {error?.code !== '401' && error && (
                    <p className='text-sm text-red-500'>
                      Incorrect Credentials
                    </p>
                  )}
                  {/* {(error?.message.includes('Email Already Exist') ||
                    error?.code?.includes('402')) &&
                    error && (
                      <p className='text-sm text-red-500'>
                        Email is associated with a different account type.{' '}
                      </p>
                    )} */}
                  <div className='flex flex-col w-full p-1'>
                    <Link
                      to='/reset-password'
                      className='text-white self-end text-sm'
                    >
                      Forgot password?
                    </Link>
                  </div>
                </div>
              </FormProvider>

              <div className='w-max space-y-1'>
                <Button
                  title='Sign in'
                  isLoading={isLoading}
                  type='submit'
                  onClick={onSubmit}
                />
                <p className='text-white text-xs sm:text-sm'>
                  Not a member?{' '}
                  <Link to='/signup' className='self-start'>
                    <span className='underline'>Create your account</span>
                  </Link>
                </p>
              </div>
            </form>
          </div>
          <footer className='self-end bg-black text-xs'>
            <img
              src={Assets.svg.boxxLogoWhite}
              alt='boxx_logo'
              className='pr-2 w-28 tall:w-40'
            />
          </footer>
        </div>
      </div>

      <div className='bg-white w-full lg:w-2/5 xl:w-[40%] h-screen sm:overflow-y-auto mt-auto sm:mt-0'>
        {/* Right Div */}
        {/* Content in the right div goes here, it will be scrollable on smaller screens */}
        <h1 className='font-open-sans font-semibold text-2xl text-gray-600 sm:w-11/12 m-auto p-5 pb-5'>
          What’s included:
        </h1>
        <InfoCards
          image={tile1}
          heading="Hackbusters <sup class='text-xs'>SM</sup> - Incident Response"
          description="Think you've been hacked?  BOXX Hackbusters <sup class='text-[10px]'>SM</sup>  are breach response experts who contain cyber incidents and can re-secure your network if needed"
        />
        <InfoCards
          image={tile2}
          heading='Cyber security basics'
          description='New to the cyber security world? Learn tips and tricks from top experts at BOXX.'
        />
        <InfoCards
          image={tile3}
          heading='Data Breaches'
          description='Scan for online breaches with our dark web monitoring service and learn what to do next.'
        />
        <InfoCards
          image={tile4}
          heading='BOXX newsroom'
          description='Stay up-to-date with the latest news in cyber safety and security with our resource centre.'
        />
        <InfoCards
          image={tile5}
          heading='Online protection for kids'
          description='Teach your kids about cyber safety with easy to follow activities and videos that are suitable for the whole family.'
        />
        <InfoCards
          image={tile6}
          heading='Digital safety Overseas'
          description='Vacation stress free. Learn how to protect yourself digitally before and during your travels.'
        />
      </div>
    </div>
  );
}
