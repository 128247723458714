/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */

export enum Roles {
  HOME = 1,
  BUSINESS = 2,
  ASSIST = 3,
  INSURED = 4,
}
