import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import LogRocket from 'logrocket';
// import { Spinner } from 'flowbite-react';
import IndexPage from '../../components/pages';
import DarkWeb from '../../components/pages/main/darkweb';
import Dashboard from '../../components/pages/main/dashboard';
import ErrorPage from '../../components/pages/errorpage';
import MainLayout from '../../components/pages/main';
import Verify from '../../components/pages/verify';
// import Score from '../../components/pages/main/score';
import SignIn from '../../components/pages/signin';
import SignUp from '../../components/pages/signup';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import AccountSetting from '../../components/pages/accountSetting';
import Landing from '../../components/pages/assessment/Landing';
import Introduction from '../../components/pages/assessment/introduction';
import Assessment from '../../components/pages/assessment';
import ManagingQuestions from '../../data/local/questions/managingOnlineRisk';
import WorkFromHomeQuestions from '../../data/local/questions/workFromHome';
import HouseholdQuestion from '../../data/local/questions/householdDigitalSafety';
import ComingSoon from '../../components/pages/comingSoon';
import ProtectingQuestions from '../../data/local/questions/protectingSesitiveData';
// import ReportBreach from '../../components/pages/main/reportBreach';
import ResetPassword from '../../components/pages/resetPassword';
import NewPassword from '../../components/pages/newPassword';
// import OrganisationAssessment from '../../components/pages/organisationQuestion';
// import ConsumerAssessment from '../../components/pages/consumerQuestion';
// import { useFetchUser } from '../hooks/useUser';
// import ZurichAssessment from '../../components/pages/zurichAssessment';
// import ZurichRiskIntroduction from '../../components/pages/zurichAssessment/components/ZurichRiskintroduction';
// import RiskIntroduction from '../../components/pages/consumerQuestion/components/Riskintroduction';
import EmailSubscription from '../../components/pages/emailSubscription';
import EmergencyRoom from '../../components/pages/emergencyRoom';
import Blog from '../../components/pages/emergencyRoom/Blog';
import article from '../../data/local/page_data/emergency_room/blogs/article';

const basename = window.location.pathname.startsWith('/consumer')
  ? '/consumer'
  : '/';

export default function MainRouter() {
  const [zDisplay, setZDisplay] = useState(false);

  // please only allow development and stagging use this
  // LogRocket.init('lxjwfl/dev-monitor');
  return (
    <BrowserRouter basename={basename}>
      <Routes>
        <Route
          path='*'
          element={<ErrorPage error={Error('Page not found')} />}
        />
        <Route
          path='/'
          element={
            <PrivateRoute>
              <IndexPage />
            </PrivateRoute>
          }
        />
        <Route
          path='signin'
          element={
            <PrivateRoute>
              <SignIn />
            </PrivateRoute>
          }
        />
        <Route
          path='signup'
          element={
            <PrivateRoute>
              <SignUp />
            </PrivateRoute>
          }
        />
        <Route
          path='reset-password'
          element={
            <PrivateRoute>
              <ResetPassword />
            </PrivateRoute>
          }
        />
        <Route
          path='new-password'
          element={
            <PrivateRoute>
              <NewPassword />
            </PrivateRoute>
          }
        />
        <Route
          path='verify'
          element={
            <PrivateRoute>
              <Verify
              // isVerified={isVerified}
              // setIsVerified={() => {
              //   setIsVerified(true);
              // }}
              />
            </PrivateRoute>
          }
        />
        <Route path='unsubscribe' element={<EmailSubscription />} />

        <Route
          path='/'
          element={
            <PublicRoute>
              <MainLayout showZAsessment={zDisplay} />
            </PublicRoute>
          }
        >
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='assessment' element={<Landing />} />
          {/* <Route path='assessment' element={<ZurichRiskIntroduction />} /> */}
          <Route path='dark-web' element={<DarkWeb />} />
          {/* <Route path='digital-risk' element={<DigitalRisk />} /> */}
          <Route path='wallet' element={<ComingSoon />} />
          <Route path='emergency-room' element={<EmergencyRoom />} />
          <Route path='digital-safety-tips' element={<ComingSoon />} />
          {/* <Route path='breach' element={<ReportBreach />} /> */}
          {/* <Route path='score' element={<Score />} /> */}
          <Route
            path='settings'
            element={
              <AccountSetting
                showZAsessment={zDisplay}
                setShowzZAssement={setZDisplay}
              />
            }
          />
          <Route
            path='introduction/managing-risk'
            element={
              <Introduction
                pdf1='/files/pdf/cyber-security/BOXX Academy - Password Safety.pdf'
                pdf2='/files/pdf/cyber-security/BOXX Academy - Removable Media.pdf'
                title='Managing Online Risk'
                url='/assessment/managing-risk'
                videoUrl='https://player.vimeo.com/video/765829047?h=f45e583207'
              />
            }
          />
          <Route
            path='introduction/sensitive-data'
            element={
              <Introduction
                pdf1='/files/pdf/sensitive-data/BOXX Academy - General Data Protection Regulation EN.pdf'
                pdf2='/files/pdf/sensitive-data/BOXX Academy - Personal Information EN.pdf'
                title='Protecting Sensitive Data'
                url='/assessment/sensitive-data'
                videoUrl='https://player.vimeo.com/video/765829067?h=56fd0aec6e'
              />
            }
          />
          <Route
            path='introduction/wfh'
            element={
              <Introduction
                pdf1='/files/pdf/wfh/BOXX Academy - Phishing EN.pdf'
                pdf2='/files/pdf/wfh/BOXX Academy - Social Engineering.pdf'
                title='Working From Home'
                url='/assessment/wfh'
                videoUrl='https://player.vimeo.com/video/765829114?h=4629195cc1'
              />
            }
          />
          <Route
            path='introduction/household'
            element={
              <Introduction
                pdf1='/files/pdf/digital-safety/BOXX Academy - Remote and Mobile Working EN.pdf'
                pdf2='/files/pdf/digital-safety/BOXX Academy - Using Personal Devices.pdf'
                title='Household Digital Safety'
                url='/assessment/household'
                videoUrl='https://player.vimeo.com/video/765828478?h=1e7462527f'
              />
            }
          />
          {/* <Route
            path='assessment/digital-risk'
            element={<ZurichAssessment />}
          /> */}
          {/* <Route
            path='digital-risk/assessment'
            element={<DigitalRiskAssessment />}
          /> */}

          <Route
            path='assessment/managing-risk'
            element={
              <Assessment
                questions={ManagingQuestions}
                title='managing online risk'
                trainingId={1}
              />
            }
          />
          <Route
            path='assessment/sensitive-data'
            element={
              <Assessment
                questions={ProtectingQuestions}
                title='protecting sensitive data'
                trainingId={2}
              />
            }
          />
          <Route
            path='assessment/wfh'
            element={
              <Assessment
                questions={WorkFromHomeQuestions}
                title='working from home'
                trainingId={3}
              />
            }
          />
          <Route
            path='assessment/household'
            element={
              <Assessment
                questions={HouseholdQuestion}
                title='household digital safety'
                trainingId={4}
              />
            }
          />
          <Route
            path='emergency-room/article-1'
            element={<Blog {...article.article_1} />}
          />
          <Route
            path='emergency-room/article-2'
            element={<Blog {...article.article_2} />}
          />
          <Route
            path='emergency-room/article-3'
            element={<Blog {...article.article_3} />}
          />
          <Route
            path='emergency-room/article-4'
            element={<Blog {...article.article_4} />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

// function DigitalRisk() {
//   const user = useFetchUser();

//   if (user.isLoading)
//     return (
//       <div className='flex flex-1 mt-10 justify-center items-center'>
//         <Spinner
//           color='warning'
//           className='self-center '
//           aria-hidden='true'
//           aria-label='Left-aligned spinner example'
//         />
//       </div>
//     );

//   return <RiskIntroduction key={new Date().getMilliseconds()} />;
// }

// function DigitalRiskAssessment() {
//   const user = useFetchUser();

//   if (user.isLoading)
//     return (
//       <div className='flex flex-1 mt-10 justify-center items-center'>
//         <Spinner
//           color='warning'
//           className='self-center '
//           aria-hidden='true'
//           aria-label='Left-aligned spinner example'
//         />
//       </div>
//     );
//   if (user.data?.data.corporate_name) return <OrganisationAssessment />;
//   return <ConsumerAssessment />;
// }
