import React from 'react';
import { FormProvider } from 'react-hook-form';
import Button from '../../app/Button';
import FormError from '../../app/FormError';
import Input from '../../app/Input';
import useVerify from './useVerify';
import FormModal from '../../app/layout/FormModal';

export default function Verify() {
  const {
    form,
    onFormSubmit,
    onResendToken,
    verifyHook,
    resendHook,
    onReturnToLogin,
    isVerified,
  } = useVerify();

  return (
    <FormModal
      heading={
        isVerified ? 'Your account has been created' : 'Verify your account'
      }
      description={
        isVerified
          ? 'Go back to the login page and sign in to the membership portal with your email and password.'
          : 'Please authenticate your account by entering the code sent to your email.'
      }
    >
      <div className='space-y-6 sm:space-y-8 w-full'>
        {!isVerified ? (
          <>
            <FormProvider {...form}>
              {verifyHook.error?.code !== '409' && (
                <FormError error={verifyHook.error} />
              )}
              <Input type='text' name='token' label='Verification code' />
            </FormProvider>
            <div className='w-1/2 space-y-6'>
              <Button
                title='Verify account'
                onClick={onFormSubmit}
                isLoading={verifyHook.isLoading}
              />
              <Button
                title='Resend Code'
                onClick={onResendToken}
                isLoading={resendHook.isLoading}
                isGray
              />
            </div>
          </>
        ) : (
          <div className='min-w-max w-1/2 mt-10 mb-10'>
            <Button title='Back to login page' onClick={onReturnToLogin} />
          </div>
        )}
      </div>
    </FormModal>
  );
}
