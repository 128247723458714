/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from '../../app/navbar/NavBar';
import SideBar from '../../app/sidebar/SideBar';

interface MainLayoutProps {
  showZAsessment: boolean;
}
export default function MainLayout(props: MainLayoutProps) {
  const [drawerVisible, setDrawerVisible] = React.useState(false);
  const onItemClick = () => {
    setDrawerVisible(false);
  };
  return (
    <div className='bg-gray-50 min-h-screen overflow-hidden'>
      <SideBar
        collapsed={!drawerVisible}
        onItemClick={onItemClick}
        showZAsessment={props.showZAsessment}
      />
      <NavBar onHamburgerClick={() => setDrawerVisible(!drawerVisible)} />
      <div
        className='h-screen pt-16 pb-5 px-2 sm:p-0 sm:pt-5 sm:ml-64  bg-transparent overflow-y-auto'
        onClick={onItemClick}
      >
        <Outlet />
      </div>
    </div>
  );
}
