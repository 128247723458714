import Question from '../Question';
import Option from '../Option';

const ManagingQuestions: Question[] = [
  new Question(
    '1',
    'What term describes an attempt to obtain sensitive information by sending an email that appears to come from a well-known website?',
    [
      new Option('1', 'Social engineering'),
      new Option('2', 'Identity theft'),
      new Option('3', 'Phishing'),
      new Option('4', 'Spam'),
    ],
    '3',
  ),

  new Question(
    '2',
    'What is the MOST serious impact of installing malicious code on your computer?',
    [
      new Option(
        '1',
        'Criminals may gain access to information stored on my computer',
      ),
      new Option('2', 'I may have to restore data from a backup'),
      new Option(
        '3',
        'Shopping websites may encourage me to spend more on each purchase',
      ),
      new Option('4', 'Programs might stop working'),
    ],
    '1',
  ),

  new Question(
    '3',
    'What does a typical phishing email often contain?',
    [
      new Option('1', 'Complicated information'),
      new Option('2', 'Text in a foreign language'),
      new Option('3', 'An offer of a prize or free gift'),
      new Option('4', 'Information that is automatically blocked'),
    ],
    '3',
  ),

  new Question(
    '4',
    'Who is the target of spear phishing?',
    [
      new Option('1', 'Specific individuals'),
      new Option('2', 'Random individuals'),
      new Option('3', 'Young individuals'),
      new Option('4', 'Elderly individuals'),
    ],
    '1',
  ),

  new Question(
    '5',
    'You receive a phone call from someone claiming there is a problem with your computer. They ask if they can install a diagnostic program. What should you do?',
    [
      new Option(
        '1',
        'Ask the caller to wait while I back up my files on my computer',
      ),
      new Option(
        '2',
        'Ask the caller for proof of identity and details of their company',
      ),
      new Option('3', 'Ask the caller to ring back at a more convenient time'),
      new Option(
        '4',
        'Ask the caller to install the program as soon as possible',
      ),
    ],
    '2',
  ),

  new Question(
    '6',
    'What should you do if you receive a call from your bank asking you to confirm your personal information?',
    [
      new Option('1', 'Give them my details as they are working for the bank'),
      new Option(
        '2',
        'Decline the request and call the bank myself to verify/report the request',
      ),
      new Option('3', 'Hang up immediately'),
      new Option(
        '4',
        'Ask the caller to provide their name and role before giving my details',
      ),
    ],
    '2',
  ),

  new Question(
    '7',
    'Which is a risk associated with a social engineering attack?',
    [
      new Option('1', "I won't be able to access my social networking sites"),
      new Option(
        '2',
        "The security patches for my software won't be kept up to date",
      ),
      new Option(
        '3',
        'Criminals will be able to gain unauthorized access to personal and financial information',
      ),
      new Option(
        '4',
        "I won't be able to open attachments that are included in emails",
      ),
    ],
    '3',
  ),

  new Question(
    '8',
    'What should you do to reduce the risk of a social engineering attack?',
    [
      new Option(
        '1',
        'I should open unfamiliar email attachments to check the content is safe',
      ),
      new Option(
        '2',
        'I should make sure all my information is stored on a central server',
      ),
      new Option(
        '3',
        'I should never take company work home to do in the evenings',
      ),
      new Option(
        '4',
        "I should check the email sender's address to be sure all sensitive emails are being sent to the correct individual",
      ),
    ],
    '4',
  ),
  new Question(
    '9',
    'Which is one of the ways to check that emails you receive are genuine?',
    [
      new Option('1', 'Reply to the email to check that the sender is genuine'),
      new Option('2', 'Forward the email to yourself before replying'),
      new Option('3', 'Respond using a different email'),
      new Option('4', "Check the sender's address is one that you recognize"),
    ],
    '4',
  ),
  new Question(
    '10',
    'Which is an example of baiting in a social engineering attack?',
    [
      new Option('1', 'Someone following me through security access control'),
      new Option(
        '2',
        'A no-reply email from a business communicating policy updates',
      ),
      new Option(
        '3',
        "Someone calling my phone pretending there's been a virus attack",
      ),
      new Option(
        '4',
        "An email from an unknown source telling me I've won a competition",
      ),
    ],
    '4',
  ),
  new Question(
    '11',
    'Which is an objective of payment diversion fraud?',
    [
      new Option('1', "To obtain access to an individual's bank account"),
      new Option('2', 'To convince someone to willingly process fake payment'),
      new Option('3', "To obtain access to an individual's email"),
      new Option(
        '4',
        'To instruct a payment from an individual into an account controlled by fraudsters using falsified payment details',
      ),
    ],
    '4',
  ),
  new Question(
    '12',
    "You receive a phone call from a business you don't recognize. They are asking for an invoice number. What should you do to verify the request?",
    [
      new Option(
        '1',
        'Provide the reference number. It is only an internal reference so therefore not confidential',
      ),
      new Option(
        '2',
        'Ask them to send you an e-mail so you can further verify their credentials before taking further action',
      ),
      new Option('3', 'Ask them for their manager'),
      new Option('4', 'Immediately put the phone down'),
    ],
    '2',
  ),
  new Question(
    '13',
    'What should you do before giving out personal information to others via phone or e-mail?',
    [
      new Option(
        '1',
        'Give them the contact details of another individual or family member who might be able to help',
      ),
      new Option(
        '2',
        'Verify the person is who they say they are, and that the request is legitimate',
      ),
      new Option(
        '3',
        'Nothing, if they have your contact details they must be legitimate',
      ),
      new Option('4', 'Ask them to confirm their name and phone number'),
    ],
    '2',
  ),
  new Question(
    '14',
    'Which of the following signs in an e-mail may indicate an attempt at payment diversion fraud?',
    [
      new Option(
        '1',
        'You are being asked to send across your banking details from someone you were not anticipating',
      ),
      new Option(
        '2',
        'You are being asked to make a payment using a method you are not aware of',
      ),
      new Option('3', 'The language that is used seems unusual'),
      new Option('4', 'All of the above'),
    ],
    '2',
  ),
  new Question(
    '15',
    'Which would be the key indicator that an e-mail from someone may not be genuine?',
    [
      new Option('1', "There isn't a company logo in the body of the e-mail"),
      new Option(
        '2',
        'The e-mail domain is different from e-mails you have previously received from that contact or organization',
      ),
      new Option('3', 'There is no phone number in the e-mail signature'),
      new Option('4', 'There is a spelling mistake in the e-mail'),
    ],
    '2',
  ),
];

export default ManagingQuestions;
