/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
import * as React from 'react';
import Option from '../../../data/local/Option';
import QuestionData from '../../../data/local/Question';

interface QuestionProps {
  question: QuestionData;
  selectedOption: string | undefined;
  onOptionClick: (option: Option) => void;
}

export default function Question(props: QuestionProps) {
  return (
    <div className='w-full my-3'>
      <div className='mb-1 dark:text-white'>
        <p className='text-md  text-black'>{props.question.question}</p>
      </div>
    </div>
  );
}
