import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useFetchUser, useUpdateUser } from '../../../app/hooks/useUser';
import UserRequest from '../../../data/requests/UserRequest';
import {
  basicFieldValidation,
  corporateNameValidation,
  emailValidation,
  policyNumberValidation,
} from '../../../utils/validations';

const schema = yup.object({
  first_name: basicFieldValidation,
  last_name: basicFieldValidation,
  email: emailValidation,
  weekly_email: yup.boolean().required('Weekly email is a required field'),
  corporate_name: corporateNameValidation,
  // .required('Corporate name is required')
  policy_number: policyNumberValidation,
  // .transform(curr => curr.replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3')),
  // .required('Policy number is required'),
});

type FormType = yup.InferType<typeof schema>;

export default function useAccountSettings() {
  const fetchUser = useFetchUser();
  const updateUser = useUpdateUser();

  const form = useForm<FormType>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',

    resolver: yupResolver(schema),
    defaultValues: fetchUser.data?.data,
  });

  // React.useEffect(() => {
  //   const value = form.watch('policy_number');
  //   form.setValue('policy_number', value?.replace(/(\d{3})(\d{3})/, '$1-$2'));
  //   if (!value) {
  //     form.setValue('policy_number', '');
  //   }
  //   return () => {};
  // }, [form.watch('policy_number')]);

  React.useEffect(() => {
    form.reset(fetchUser.data?.data);
  }, [fetchUser.data]);

  const onCancelChanges = () => {
    form.reset(fetchUser.data?.data);
    fetchUser.refetch();
    toast.success('Changes reverted!');
  };

  const onFormSubmit = async (data: any) => {
    await updateUser.mutateAsync({
      corporate_name: data.corporate_name,
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      policy_number: data.policy_number,
      weekly_email: data.weekly_email,
    } as UserRequest);
    toast.success('Profile updated successfully!');
    fetchUser.refetch();
  };

  return {
    form,
    onFormSubmit: form.handleSubmit(onFormSubmit),
    isLoading: updateUser.isLoading,
    onCancelChanges,
  };
}
