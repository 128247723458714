/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// /* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Assets from '../../../assets';
import Button from '../../app/Button';
import PageLayout from '../../app/layout/PageLayout';
import BackNavigationButton from '../../app/BackNavigationButton';
import boxxNewsRoom from '../../../assets/login/boxx_newsroom.png';

interface IntroductionProps {
  title: string;
  url: string;
  videoUrl: string;
  pdf1: string;
  pdf2: string;
  hideVideo?: boolean;
}

export default function Introduction(props: IntroductionProps) {
  return (
    <PageLayout
      leftPanel={<LeftPanelContent url={props.url} title={props.title} />}
      rightPanelContent={
        <RightBarContent
          videoUrl={props.videoUrl}
          pdf1={props.pdf1}
          pdf2={props.pdf2}
        />
      }
      filterBarContent={<FilterBarContent />}
      RightPanelFooter={<div />}
    />
  );
}

function LeftPanelContent({ url, title }: { url: string; title: string }) {
  const navigate = useNavigate();
  return (
    <div className='space-y-8'>
      <BackNavigationButton
        onButtonClick={() => {
          navigate('/assessment');
        }}
        title='security training'
      />
      <h1 className='page-heading-3'>{title}</h1>
      <div className='w-full space-y-3 text-sm font-normal text-dark-gray'>
        <p>
          Online services are used by most of us for business and personal use.
          Understand the risks you face and sensible precautions to help keep
          you safe online.
        </p>
        <div className='w-full space-y-3'>
          <p>
            Within this pathway are video animations, eLearning, summary
            document (PDF) and game learning modules covering:{' '}
          </p>
          <ul className='list-disc list-inside pl-3'>
            <li>Password Safety</li>
            <li>Removable Media</li>
          </ul>
        </div>
        <p>
          The pathway starts with an optional diagnostic assessment and finishes
          with an assessment (15 questions total).
        </p>
      </div>
      <div className='w-60'>
        <Button title='Start my assessment' onClick={() => navigate(url)} />
      </div>
    </div>
  );
}

function FilterBarContent() {
  return <div>E-Learning material</div>;
}

function RightBarContent({
  videoUrl,
  pdf1,
  pdf2,
}: {
  videoUrl: string;
  pdf1: string;
  pdf2: string;
}) {
  return (
    <div className='space-y-10'>
      <div className='w-full'>
        <iframe
          src={`${videoUrl}`}
          title='wfh-video'
          className='aspect-video bg-black rounded-lg'
          width='100%'
        />
      </div>
      <div className='w-full space-y-5'>
        <div className='flex w-full justify-between  rounded-xl border py-3 px-5'>
          <div className='flex space-x-4 justify-center items-center'>
            <img
              src={boxxNewsRoom}
              alt='NewsRoomImage'
              className='w-[68px] sm:w-[82px] rounded-xl'
            />
            <h3 className='font-medium text-xs sm:text-sm '>
              Using personal devices key points
            </h3>
          </div>
          <a
            href={pdf1}
            target='_blank'
            rel='noreferrer'
            className='flex items-center justify-center'
          >
            <img src={Assets.svg.icDownload} alt='download' />
          </a>
        </div>
        <div className='flex w-full justify-between  rounded-xl border py-3 px-5'>
          <div className='flex space-x-4 justify-center items-center'>
            <img
              src={Assets.svg.icPSD}
              alt='PSDImage'
              className='w-[68px] sm:w-[82px] rounded-xl'
            />
            <h3 className='font-medium text-xs sm:text-sm'>
              Remote and mobile working key points
            </h3>
          </div>
          <a
            href={pdf2}
            target='_blank'
            rel='noreferrer'
            className='flex items-center justify-center'
          >
            <img src={Assets.svg.icDownload} alt='download' />
          </a>
        </div>
      </div>
    </div>
  );
}
