const Constants = {
  baseUrl:
    process.env.REACT_APP_BASEURL ??
    // 'http://bmp-lb-stg-1398365911.us-east-1.elb.amazonaws.com/api/v1',
    // 'http://bmp-lb-dev-1163763617.us-east-1.elb.amazonaws.com/api/v1/',
    // 'https://boxxinsurance-ruby-api-dev-yk5im.ondigitalocean.app/api/v1',
    'https://bmp-api.boxxinsurance.com/api/v1',
};

export default Constants;
