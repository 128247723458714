/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-constructor */

import { OptionType } from "../Enum/OptionType";

export default class Option {
  constructor(
    readonly id: string, 
    readonly option: string,
    readonly type?: OptionType,
    readonly groupBy?: string,
  ) {}
}
