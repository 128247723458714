import React from 'react';
import { useFetchUser } from '../../../../app/hooks/useUser';
import DashboardTiles from './components/DashboardTiles';
import tile1 from '../../../../assets/dashboard/consumer_tile_1.webp';
import tile2 from '../../../../assets/dashboard/consumer_tile_2.webp';
import tile3 from '../../../../assets/dashboard/consumer_tile_3.webp';
import tile4 from '../../../../assets/dashboard/consumer_tile_4.webp';
import tile5 from '../../../../assets/dashboard/consumer_tile_5.webp';
import tile6 from '../../../../assets/dashboard/consumer_tile_6.webp';

function Dashboard() {
  const user = useFetchUser();
  return (
    <div id='mainpage' className='min-h-full lg:h-full p-2 sm:px-5 tall:py-5'>
      <div className='flex flex-col min-h-full m-auto max-w-5xl xl:w-11/12 2xl:max-w-7xl space-y-3 tall:space-y-8'>
        <div className='flex flex-col space-y-1 w-full tall:space-y-2'>
          {' '}
          <h1 className='page-heading '>
            {' '}
            Welcome back {user.data?.data.first_name},
          </h1>
          <p className='page-description '>
            Let’s check out your tool box below and get you cyber safe.{' '}
          </p>
          <hr className=' border border-neutral-200  ' />
        </div>

        <div className='flex flex-col h-full w-full justify-center items-center lg:items-stretch lg:justify-around  lg:space-y-3 tall:space-y-6'>
          <div className='flex flex-col lg:flex-row justify-center items-center md:justify-between md:items-start'>
            <DashboardTiles
              title='Digital safety overseas'
              description='Learn how to protect yourself digitally before and during your travels.'
              image={tile1}
              forwardLink='/assessment'
            />

            <DashboardTiles
              title='Data breaches'
              description='Scan for your leaked info online with our dark web monitoring service.'
              image={tile2}
              forwardLink='/dark-web'
            />

            <DashboardTiles
              title='Online protection for kids'
              description='Online protection & education for Teenagers & Kids.'
              image={tile3}
              forwardLink='/assessment'
            />
          </div>
          <div className='flex flex-col lg:flex-row justify-center items-center md:justify-between md:items-start'>
            <DashboardTiles
              title='BOXX newsroom'
              description='Stay up-to-date with the latest news in cyber safety and security with our resource centre'
              image={tile4}
              forwardLink='https://boxxinsurance.com/company/newsroom'
              newTab
            />
            <DashboardTiles
              title='Cyber security basics'
              description='New to the cyber security world? Learn tips and tricks from top experts at BOXX.'
              image={tile5}
              forwardLink='/assessment'
            />
            <DashboardTiles
              title="Hackbusters <sup class='text-xs'>SM</sup> - Incident Response"
              description="Think you've been hacked?  BOXX Hackbusters <sup class='text-[10px]'>SM</sup>  are breach response experts who contain cyber incidents and can re-secure your network if needed"
              image={tile6}
              forwardLink='/emergency-room'
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
