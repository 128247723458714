import React from 'react';
import { NavLink } from 'react-router-dom';
// import Utils from '../../../utils/Utils';
import User from '../../../data/local/User';
import Assets from '../../../assets';

interface SideBarListProps {
  onItemClick: () => void;
  user: User | undefined;
  showZAsessment: boolean;
}

export default function SideBarList(props: SideBarListProps) {
  return (
    <ul className='space-y-3  font-medium text-sm p-3 tall:mt-20  text-[#3F3F75] mt-10 mb-10'>
      <li>
        <NavLink
          onClick={props.onItemClick}
          to='/dashboard'
          className={getClassName}
        >
          {({ isActive }) => (
            <>
              <img src={Assets.svg.icHome} alt='logo_home' />

              <span className={getIsActiveStyle(isActive)}>Dashboard</span>
            </>
          )}
        </NavLink>
      </li>
      <li>
        {/* {Utils.canView(props.user, 'darkweb') && ( */}
        <NavLink
          onClick={props.onItemClick}
          to='/dark-web'
          className={getClassName}
        >
          {({ isActive }) => (
            <>
              <img src={Assets.svg.icDarkWeb} alt='logo_darkWeb' />
              <span className={getIsActiveStyle(isActive)}>Data breaches</span>
            </>
          )}
        </NavLink>
        {/* )} */}
      </li>
      {/* <li>
        <NavLink
          onClick={props.onItemClick}
          to='/digital-risk'
          className={getClassName}
        >
          {({ isActive }) => (
            <>
              <svg
                aria-hidden='true'
                className='flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z'
                  clipRule='evenodd'
                />
              </svg>
              <span className={getIsActiveStyle(isActive)}>
                Digital Risk Assessment
              </span>
            </>
          )}
        </NavLink>
      </li> */}
      {props.showZAsessment && (
        <li>
          <NavLink
            onClick={props.onItemClick}
            to='/assessment'
            className={getClassName}
          >
            {({ isActive }) => (
              <>
                <svg
                  aria-hidden='true'
                  className='flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    d='M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z'
                    clipRule='evenodd'
                  />
                </svg>
                <span className={getIsActiveStyle(isActive)}>
                  Zürich Assessment
                </span>
              </>
            )}
          </NavLink>
        </li>
      )}

      {/* <li>
        {Utils.canView(props.user, 'score') && (
          <NavLink
            onClick={props.onItemClick}
            to='/score'
            className={getClassName}
          >
            {({ isActive }) => (
              <>
                <img src={Assets.svg.icSecurityScore} alt='logo_security' />
                <span className={getIsActiveStyle(isActive)}>
                  Security Score
                </span>
              </>
            )}
          </NavLink>
        )}
      </li> */}
      <li>
        <NavLink
          onClick={props.onItemClick}
          to='/assessment'
          className={getClassName}
        >
          {({ isActive }) => (
            <>
              <img
                src={Assets.svg.icSecurityTraining}
                alt='logo_securityTraining'
              />
              <span className={getIsActiveStyle(isActive)}>
                Security training
              </span>
            </>
          )}
        </NavLink>
      </li>
      {/* <li>
        <NavLink
          onClick={props.onItemClick}
          to='/wallet'
          className={getClassName}
        >
          {({ isActive }) => (
            <>
              <img src={Assets.svg.icPolicyWallet} alt='logo_policyWallet' />

              <span className={getIsActiveStyle(isActive)}>Policy details</span>
            </>
          )}
        </NavLink>
      </li> */}
      {/* <li>
        <NavLink
          onClick={props.onItemClick}
          to='/breach'
          className={getClassName}
        >
          {({ isActive }) => (
            <>
              <img src={Assets.svg.icReportBreach} alt='logo_breach' />

              <span className={getIsActiveStyle(isActive)}>
                Report a Breach
              </span>
            </>
          )}
        </NavLink>
      </li> */}
      <li>
        <NavLink
          onClick={props.onItemClick}
          to='/emergency-room'
          className={getClassName}
        >
          {({ isActive }) => (
            <>
              <img
                src={Assets.svg.icEmergencyRoomSideBar}
                alt='icon_emergencyRoom'
              />
              <span className={getIsActiveStyle(isActive)}>
                Emergency support
              </span>
            </>
          )}
        </NavLink>
      </li>
      {/* <li>
        <NavLink
          onClick={props.onItemClick}
          to='/digital-safety-tips'
          className={getClassName}
        >
          {({ isActive }) => (
            <>
              <img src={Assets.svg.icDigitalSafetyTips} alt='logo_safetyTips' />
              <span className={getIsActiveStyle(isActive)}>
                Digital safety tips
              </span>
            </>
          )}
        </NavLink>
      </li> */}
    </ul>
  );
}

function getClassName(props: { isActive: boolean }): string {
  return props.isActive
    ? 'flex items-center p-2 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700  transition duration-300 text-[#262647]'
    : 'flex items-center p-2 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700  transition duration-300';
}

function getIsActiveStyle(isActive: boolean): string {
  return isActive
    ? 'flex-1 ml-3      whitespace-nowrap border-b border-[#262647] w-full transition duration-700'
    : 'flex-1 ml-3      whitespace-nowrap ';
}
