import { useMutation, useQuery } from 'react-query';
import axios from '../../utils/AxiosInterceptor';
import BaseResponse from '../../data/response/BaseResponse';
import Constants from '../../utils/Constants';
import { BaseErrorResponse } from '../../data/response/BaseErrorResponse';
import cardsData from '../../data/local/page_data/training/right-panel-tiles';

interface IAssessmentRequest {
  training_id: number;
  score: number;
}

interface IModuleScoreResponse {
  training_name: string;
  training_id: number;
  score: number;
}

interface IAssessmentDetails {
  description: string;
  image: string;
  onClickNavigateTo: string;
  title: string;
  score: number | null;
}

const getQuestions = async (): Promise<
  BaseResponse<IModuleScoreResponse[]>
> => {
  const response = await axios(Constants.baseUrl).get(`/training-score`);
  return response.data;
};

const postScore = async (
  payLoad: IAssessmentRequest,
): Promise<BaseResponse<any>> => {
  const response = await axios(Constants.baseUrl).post(
    `/training-score`,
    payLoad,
  );
  return response.data;
};

export function useAssessmentScore() {
  return useQuery(['security-training-scores'], getQuestions, {
    select: combineData,
  });
}
export function useSaveTrainingScore() {
  return useMutation<BaseResponse<any>, BaseErrorResponse, IAssessmentRequest>(
    postScore,
  );
}

function combineData(
  response: BaseResponse<IModuleScoreResponse[]>,
): IAssessmentDetails[] {
  const totalAssessments = 4; // Total number of assessments
  const scoreList: (number | null)[] = Array(totalAssessments).fill(null); // Initialize with null values
  // const newCardsData: IAssessmentDetails[] = [];
  response.data.forEach(item => {
    const scoreIndex = (item?.training_id ?? 1) - 1;
    scoreList[scoreIndex] = item?.score;
  });
  return cardsData.map((item, index) => {
    return {
      ...item,
      score: scoreList[index] ?? null,
    };
  });
}
