import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import {
  useResend,
  useVerify as useVerifyMutate,
} from '../../../app/hooks/useUser';
import VerifyRequest from '../../../data/requests/VerifyRequest';
import { codeValidation, emailValidation } from '../../../utils/validations';
import { useAuth } from '../../../app/contexts/AuthContext';

const schema = yup.object({
  email: emailValidation,
  token: codeValidation,
});

type FormType = yup.InferType<typeof schema>;

export default function useVerify() {
  const verify = useVerifyMutate();
  const resend = useResend();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const auth = useAuth();

  React.useEffect(() => {
    if (verify.error?.code === '409') {
      toast.success('User is already verified.', {
        duration: 1000,
      });
      auth?.setVerificationTrue();
    }
  }, [verify.error]);

  const form = useForm<FormType>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      email: decodeURIComponent(searchParams.get('email') ?? ''),
      token: '',
    },
  });

  const onReturnToLogin = () => {
    setTimeout(() => {
      navigate('/signin');
    }, 1000);
  };

  const onFormSubmit = async (data: FormType) => {
    await verify.mutateAsync({
      ...(data as VerifyRequest),
      app_id: '2XybYTuPwHu7dtWPuie4llHMgb1',
    });
    toast.success('User verified successfully. Please login to continue.');
    // setTimeout(() => {
    //   navigate(-1);
    // }, 1000);
    auth?.setVerificationTrue();
  };

  const onResendToken = async () => {
    await resend.mutateAsync({
      email: form.getValues('email'),
      app_id: '2XybYTuPwHu7dtWPuie4llHMgb1',
    });
    form.reset();
    toast.success('Email has been sent with verification code');
  };

  return {
    form,
    verifyHook: verify,
    resendHook: resend,
    isVerified: auth?.isVerified || false,
    onFormSubmit: form.handleSubmit(onFormSubmit),
    onResendToken,
    onReturnToLogin,
  };
}
